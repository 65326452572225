import React from 'react';
import { observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@inject('store')
@withTranslation(['common', 'admin'])
@observer
export class UsersPopupMenu extends React.Component {
    @observable loading = true;

    async componentDidMount() {
        this._update();
    }

    _update = () => {
        this.loading = true;
        this.props.store.users
            .listUsers(true)
            .then(() => {
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    };

    render() {
        const { t, allowAllUsers } = this.props;

        let options;
        if (this.loading) {
            options = [
                <option value="" key="loading">
                    {t('common:loading')}...
                </option>
            ];
        } else {
            if (allowAllUsers) {
                options = [
                    <option value="***" key="allusers">
                        {t('admin:users-selector.all-fishermen')}
                    </option>
                ];
            } else {
                options = [
                    <option value="" key="empty">
                        {t('common:select')}...
                    </option>
                ];
            }

            options = options.concat(
                this.props.store.users.list.map((user) => (
                    <option value={user.id} key={`${user.id}-all`}>
                        {user.fullName()} [{user.identifier}]
                    </option>
                ))
            );
        }

        return (
            <OrgChangeObserver onChange={this._update}>
                <select {...this.props.selectProps}>{options}</select>
            </OrgChangeObserver>
        );
    }
}
