import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { isBefore, endOfToday, isSameMonth } from 'date-fns';

import { api } from '@SUPPORT/api';
import { i18n } from '@SUPPORT/i18n';
import { rawValidator } from '@SUPPORT/validator';
import { formatLocaleDate, padLeft, parseFloatingNum } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { WithPermission } from '@COMPONENTS/common/WithPermission';

import { GearsPopupMenu } from './GearsPopupMenu';
import { SpeciesPopupMenu } from './SpeciesPopupMenu';
import { ZonesPopupMenu, setZonesPopupValue } from './ZonesPopupMenu';

@inject('store')
@withTranslation(['common'])
@observer
export class DeclarationDialog extends React.Component {
    @observable saving = false;
    @observable unit = 'kg';

    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    date: { type: 'text', rules: 'required_without:day|declDate', placeholder: 'dd/mm/yyyy' },
                    day: { type: 'text', rules: 'required_without:date|fdayDlg', placeholder: 'dd' },
                    time: { type: 'text', rules: 'required|ftime', placeholder: 'hh:mm' },
                    durationHH: { type: 'text', rules: 'numeric' },
                    durationMM: { type: 'text', rules: 'numeric' },
                    zone: { rules: 'required' },
                    landingPort: { rules: '' },
                    gear: { rules: '' },
                    technique: { rules: '' },
                    restocking: { type: 'checkbox' },
                    price: { rules: 'fnumeric' },
                    value: { type: 'text', rules: 'required|fnumeric' },

                    species: {
                        rules: 'required',
                        hooks: {
                            onChange: (field) => {
                                const { store } = this.props;
                                this._syncGearRules(store.declarationDialog.user, field.value);
                                this._setUnit(store.species.orgUnits.get(field.value) || 'kg');
                                setZonesPopupValue(
                                    store.declarationDialog.user,
                                    field.value,
                                    this.form.select('zone').value
                                );
                            }
                        }
                    },

                    buyerOrg: {}
                }
            },
            {
                plugins: {
                    dvr: dvr({
                        package: rawValidator,
                        extend: ({ validator }) => {
                            validator.register(
                                'fdayDlg',
                                (value) => {
                                    if (!this.props.declaration) {
                                        return false;
                                    }

                                    const paddedValue = value.length === 1 ? `0${value}` : value;
                                    const date = Date.parse(
                                        `${formatLocaleDate(
                                            this.props.declaration.eventDate,
                                            'yyyy-MM'
                                        )}-${paddedValue}T13:00:00`
                                    );

                                    return (
                                        isSameMonth(date, this.props.declaration.eventDate) &&
                                        isBefore(date, endOfToday())
                                    );
                                },
                                'Invalid day'
                            );
                        }
                    })
                },
                options: { validateOnChange: true }
            }
        );
    }

    componentDidUpdate(prevProps) {
        const { props } = this;
        if (!prevProps.declaration && props.declaration) {
            this._setUnit(this.props.store.species.orgUnits.get(props.declaration.species.code) || 'kg');

            if (this.props.withPartialDate) {
                this.form.select('day').set(formatLocaleDate(props.declaration.eventDate, 'dd'));
            } else {
                this.form.select('date').set(formatLocaleDate(props.declaration.eventDate, 'P'));
            }

            const valueDivider = this.unit === 'kg' ? 1000 : 1;
            this.form.select('value').set(props.declaration.value / valueDivider);

            this.form.select('time').set(formatLocaleDate(props.declaration.eventDate, 'p'));
            this.form.select('durationHH').set(Math.floor(props.declaration.duration / 60));
            this.form.select('durationMM').set(props.declaration.duration % 60);
            this.form
                .select('restocking')
                .set(
                    props.declaration.species.code === 'EL1' &&
                        !!props.declaration.tags &&
                        props.declaration.tags.includes('restocking')
                );

            if (props.declaration.isNew) {
                this.form.select('species').set(props.declaration.species.code === 'EL1' ? 'EL1' : '');
            } else {
                this.form.select('species').set(props.declaration.species.code);
            }

            if (props.declaration.zoneId) {
                this.form.select('zone').set(props.declaration.zoneId);
            }

            if (props.declaration.landingPort) {
                this.form.select('landingPort').set(props.declaration.landingPort.id);
            }

            if (props.declaration.gear) {
                this.form.select('gear').set(props.declaration.gear.id);
            } else if (props.declaration.technique) {
                this.form.select('technique').set(props.declaration.technique.id);
            }

            if (this.props.store.buyers && props.declaration.buyerOrgId) {
                this.props.store.buyers.listAll().then(() => {
                    this.form.select('buyerOrg').set(props.declaration.buyerOrgId);
                });
            }

            if (props.declaration.price) {
                this.form.select('price').set(props.declaration.price / 100);
            }

            const species = this.form.select('species').value;
            this._syncGearRules(props.store.declarationDialog.user, species, false);

            this.form.validate();
        }
    }

    _syncGearRules = (user, species, validateForm = true) => {
        const rules = user.hasTag('pap') && species !== 'EL1' ? 'required' : '';
        this.form.select('gear').set('rules', rules);
        if (validateForm) {
            this.form.validate();
        }
    };

    @action.bound
    _setSaving(saving = true) {
        this.saving = saving;
    }

    @action.bound
    _setUnit(unit) {
        this.unit = unit;
    }

    @action
    _setLoadingInfo(loading = true) {
        this.loadingInfo = loading;
    }

    _cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _delete = (evt) => {
        evt.preventDefault();

        const ok = confirm(i18n.t('user:declarations.report.remove-alert.line1'));
        if (!ok) {
            return;
        }

        this._setSaving();
        this.props.declaration
            .deprecate()
            .then(() => {
                const afterSave = this.props.store.declarationDialog.afterSave || (() => {});
                this._setSaving(false);
                this._dismiss();
                afterSave();
            })
            .catch(() => this._setSaving(false));
    };

    _save = (evt) => {
        evt.preventDefault();

        this._setSaving();

        const values = this.form.values();

        const date = this.props.withPartialDate
            ? new Date(
                  `${formatLocaleDate(this.props.declaration.eventDate, 'yyyy-MM')}-${padLeft(values.day, 2, 0)}T${
                      values.time
                  }:00`
              )
            : new Date(`${values.date.replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3-$2-$1')}T${values.time}:00`);

        const valueMultiplier = this.unit === 'kg' ? 1000 : 1;
        const rawValue = values.value.toString() || '0';
        const value = Math.round(parseFloatingNum(rawValue) * valueMultiplier);
        const params = {
            type: this.props.store.session.info.user.type === 'admin' ? 'org' : 'web',
            date: date.toISOString(),
            species: values.species,
            zoneId: values.zone,
            value: value
        };

        if (values.durationHH !== '' || values.durationMM !== '') {
            params.duration = parseInt(values.durationHH || '0', 10) * 60 + parseInt(values.durationMM || '0');
        }

        if (params.species === 'EL1') {
            params.tags = values.restocking ? 'restocking' : 'consumption';
        }

        if (values.gear !== '') {
            params.gearId = parseInt(values.gear, 10);
        }

        if (values.technique !== '') {
            params.techniqueId = parseInt(values.technique, 10);
        }

        if (values.landingPort !== '') {
            params.landingPortId = parseInt(values.landingPort, 10);
        }

        if (values.buyerOrg) {
            params.buyerOrgId = parseInt(values.buyerOrg, 10);
        }

        if (values.price) {
            params.price = Math.round(parseFloatingNum(values.price) * 100);
        }

        const promise = this.props.store.declarationDialog.declaration.isNew
            ? api.createDeclaration(this.props.store.declarationDialog.user.id, params)
            : api.updateDeclaration(
                  this.props.store.declarationDialog.user.id,
                  this.props.store.declarationDialog.declaration.id,
                  params
              );

        promise
            .then(() => {
                const afterSave = this.props.store.declarationDialog.afterSave || (() => {});
                this._setSaving(false);
                this._dismiss();
                afterSave();
            })
            .catch(() => {
                this._setSaving(false);
            });
    };

    _dismiss = () => {
        this.props.store.declarationDialog.dismiss();
        this.form.clear();
        this.saving = false;
        this.loadingInfo = false;
        this.lastParams = null;
    };

    render() {
        const declaration = this.props.store.declarationDialog.declaration;
        if (!declaration) {
            return null;
        }

        const { t } = this.props;
        return (
            <div className={classNames('modal', { show: this.props.store.declarationDialog.show })}>
                <div className="sheet">
                    <div className="title">
                        <h3>
                            {declaration.isNew
                                ? t('common:declaration-dialog.new-declaration')
                                : t('common:declaration-dialog.modify-declaration')}
                        </h3>
                        <div>
                            <SVGObject objectId="exitCross" onClick={this._dismiss} />
                        </div>
                    </div>

                    <div className="scroll">
                        <ul className="reportSheet">
                            <li>
                                <label>{t('common:date')} :</label>
                                {this.props.withPartialDate ? (
                                    <div>
                                        <input className="short" {...this.form.select('day').bind()} />
                                        <p>{formatLocaleDate(declaration.eventDate, 'MMMM yyyy')}</p>
                                    </div>
                                ) : (
                                    <input className="date" {...this.form.select('date').bind()} />
                                )}
                            </li>

                            {this.props.store.declarationDialog.user.hasAnyOfTag(['pap', 'pal', 'pep', 'civ']) ? (
                                <li>
                                    <label>{t('common:duration')} :</label>
                                    <div>
                                        <input className="twoDigits" {...this.form.select('durationHH').bind()} />
                                        <p>h</p>
                                        <input className="twoDigits" {...this.form.select('durationMM').bind()} />
                                        <p>mn</p>
                                    </div>
                                </li>
                            ) : (
                                <li>
                                    <label>{t('common:time')} :</label>
                                    <input className="time" {...this.form.select('time').bind()} />
                                </li>
                            )}

                            <li>
                                <label>{t('common:species')} :</label>
                                <SpeciesPopupMenu
                                    user={this.props.store.declarationDialog.user}
                                    selectProps={this.form.select('species').bind()}
                                />
                            </li>

                            <li>
                                <label>{t('common:zone-full')} :</label>
                                <ZonesPopupMenu
                                    forSpecies={this.form.select('species').value}
                                    user={this.props.store.declarationDialog.user}
                                    declaration={this.props.store.declarationDialog.declaration}
                                    selectCallback={(zone) => {
                                        this.form.select('zone').set(zone.zoneId);
                                        this.form.validate();
                                    }}
                                    selectProps={this.form.select('zone').bind()}
                                />
                            </li>

                            {this.props.store.session.info.org.hasFeature('landing_ports') && (
                                <li>
                                    <label>Lieu de débarquement&nbsp;:</label>
                                    <select {...this.form.select('landingPort').bind()}>
                                        <option value="">Sélectionnez...</option>
                                        {Array.from(this.props.store.landingPorts.grouped.keys()).map((group) => (
                                            <optgroup label={group} key={group}>
                                                {this.props.store.landingPorts.grouped.get(group).map((lp) => (
                                                    <option value={lp.id} key={lp.id}>
                                                        {lp.name}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </select>
                                </li>
                            )}

                            {this.props.store.session.info.org.hasTag('pal') ? (
                                <li>
                                    <label>{t('common:technique')} :</label>
                                    <select {...this.form.select('technique').bind()}>
                                        <option value="">{t('common:select')}</option>
                                        {this.props.store.techniques.list.map((t) => (
                                            <option value={t.id} key={t.id}>
                                                {t.name}
                                            </option>
                                        ))}
                                    </select>
                                </li>
                            ) : (
                                <li>
                                    <label>{t('common:gear')} :</label>
                                    <GearsPopupMenu
                                        user={this.props.store.declarationDialog.user}
                                        selectCallback={(gear) => {
                                            this.form.select('gear').set(gear.id);
                                            this.form.validate();
                                        }}
                                        selectProps={this.form.select('gear').bind()}
                                    />
                                </li>
                            )}

                            <li>
                                <label>{t('common:weight')} :</label>
                                <div>
                                    <input className="midSize" {...this.form.select('value').bind()} />
                                    {this.unit}
                                </div>
                            </li>

                            {this.form.select('species').value === 'EL1' && (
                                <li>
                                    <label />
                                    <input {...this.form.select('restocking').bind()} />
                                    <label htmlFor={this.form.select('restocking').id}>{t('common:restocking')}</label>
                                </li>
                            )}

                            {this.props.store.declarationDialog.user.hasTag('pap') ? (
                                <li>
                                    <label>{t('common:price')} :</label>
                                    <div>
                                        <input className="short" {...this.form.select('price').bind()} />
                                        <p>€</p>
                                    </div>
                                </li>
                            ) : null}

                            <WithPermission name="VIEW_BUYERS_INFO">
                                {this.props.store.buyers && declaration && declaration.buyerOrgId ? (
                                    <li>
                                        <label>{t('common:buyer')}</label>
                                        <select {...this.form.select('buyerOrg').bind()}>
                                            <option value="">{t('common:select')}...</option>
                                            {this.props.store.buyers.list.map((buyerOrg) => (
                                                <option value={buyerOrg.id} key={buyerOrg.id}>
                                                    {buyerOrg.name}
                                                </option>
                                            ))}
                                        </select>
                                    </li>
                                ) : null}
                            </WithPermission>
                        </ul>
                    </div>

                    <div className="exit">
                        <div>
                            {!this.props.store.declarationDialog.declaration.isNew && (
                                <button className="codeRed" onClick={this._delete}>
                                    Supprimer la déclaration
                                </button>
                            )}
                        </div>{' '}
                        <div>
                            <button onClick={this._dismiss}>{t('common:cancel')}</button>
                            <button
                                disabled={!this.form.isValid || this.loadingInfo || this.saving}
                                onClick={this._save}
                            >
                                {declaration.isNew ? t('common:declare') : t('common:modify')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
