import React from 'react';
import { observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@inject('store')
@withTranslation(['common'])
@observer
export class GearsPopupMenu extends React.Component {
    @observable loading = true;
    @observable last = null;

    componentDidMount() {
        this._update();
    }

    _update = () => {
        this.loading = true;
        this.props.user
            .listLastDeclarationGears()
            .then((gears) => {
                this.last = gears;
                this.loading = false;
            })
            .catch(() => (this.loading = false));
    };

    _renderOptionLabel(gear) {
        if (this.props.renderOptionLabel) {
            return this.props.renderOptionLabel(gear);
        }

        return gear.name;
    }

    render() {
        const { t, allowCatchAllGear } = this.props;

        let options;
        if (this.loading) {
            options = [
                <option value="" key="loading">
                    {t('common:loading')}...
                </option>
            ];
        } else {
            if (allowCatchAllGear) {
                options = [
                    <option value="***" key="catchall">
                        {t('common:all-gears')}
                    </option>
                ];
            } else {
                options = [
                    <option value="" key="empty">
                        {t('common:select')}...
                    </option>
                ];
            }

            const fullList = this.props.store.gears.tagged(this.props.user.tags).map((gear) => (
                <option value={gear.id} key={gear.id}>
                    {this._renderOptionLabel(gear)}
                </option>
            ));

            if (this.last && this.last.length > 0) {
                const lastList = this.last.map((id) => {
                    const gear = this.props.store.gears.getBy('id', id);
                    if (!gear) {
                        return null;
                    }

                    return (
                        <option value={gear.id} key={gear.id}>
                            {this._renderOptionLabel(gear)}
                        </option>
                    );
                });

                options.push(
                    <optgroup label={t('common:declaration-dialog.last-used-gears') + ' :'} key="last">
                        {lastList}
                    </optgroup>
                );

                options.push(
                    <optgroup label={t('common:all-gears') + ' :'} key="all">
                        {fullList}
                    </optgroup>
                );
            } else {
                options = options.concat(fullList);
            }
        }

        return (
            <OrgChangeObserver onChange={this._update}>
                <select {...this.props.selectProps}>{options}</select>
            </OrgChangeObserver>
        );
    }
}
