import React from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { isSameMonth, startOfMonth, addMonths, addDays, isAfter } from 'date-fns';

import { formatLocaleDate, formatDeclarationValue, formatDuration, capitalizeWords } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@inject('store')
@withTranslation(['common'])
class DeclarationBlock extends React.Component {
    get _canEdit() {
        const now = new Date();
        const startOfFollowingMonth = startOfMonth(addMonths(this.props.declaration.eventDate, 1));
        const startOfFollowingMonthPlusFiveDays = addDays(startOfFollowingMonth, 5);
        const allowedForSpecies = !(
            this.props.declaration.species.code === 'EL1' && this.props.store.session.info.user.type === 'user'
        );

        return (
            allowedForSpecies &&
            (!isAfter(now, startOfFollowingMonthPlusFiveDays) || isSameMonth(now, this.props.declaration.eventDate))
        );
    }

    get _canRemove() {
        return this._canEdit;
    }

    _editDeclaration = () => {
        const store =
            this.props.user.hasTag('pfp') && this.props.declaration.species.code !== 'EL1'
                ? this.props.store.declarationWizard
                : this.props.store.declarationDialog;
        store.showForDeclaration(this.props.user, this.props.declaration, this.props.afterSave);
    };

    _removeDeclaration = () => {
        this.props.remove(this.props.declaration);
    };

    render() {
        const { t } = this.props;

        let CR = null;
        if (this.props.declaration.tags && this.props.declaration.tags.includes('consumption')) {
            CR = t('common:consumption');
        } else if (this.props.declaration.tags && this.props.declaration.tags.includes('restocking')) {
            CR = t('common:restocking');
        }

        let gearName, gearDetail;
        if (this.props.declaration.gear && this.props.declaration.gear.name) {
            const match = this.props.declaration.gear.name.match(/(.*)\((.{2,})\)/);
            if (match) {
                gearName = match[1];
                gearDetail = match[2];
            } else {
                gearName = this.props.declaration.gear.name;
            }
        }

        let gearInfo;
        if (this.props.declaration.gearInfo) {
            const GEAR_INFO_LABEL = {
                lengthInfo: t('common:gear-info.length'),
                surfaceInfo: t('common:gear-info.surface'),
                meshInfo: t('common:gear-info.mesh'),
                numHooksInfo: t('common:gear-info.num-hooks'),
                numGearsInfo: t('common:gear-info.num-gears')
            };

            const GEAR_INFO_UNIT = {
                lengthInfo: 'm',
                surfaceInfo: 'm2',
                meshInfo: 'mm'
            };

            gearInfo = Array.from(this.props.declaration.gearInfo.entries())
                .map(([key, value]) => {
                    const label = GEAR_INFO_LABEL[key];
                    const unit = GEAR_INFO_UNIT[key] || '';
                    return `${label}: ${value}${unit}`;
                })
                .join(', ');
        }

        return (
            <figure>
                <span className="whenWhere">
                    <p>
                        <span className="zone">{this.props.declaration.zoneName}</span>
                    </p>
                    <p>
                        {!this.props.user.hasAnyOfTag(['pap', 'pfp', 'pal']) && (
                            <span className="time">
                                {formatLocaleDate(this.props.declaration.eventDate, 'p')}&nbsp;
                            </span>
                        )}
                        {this.props.declaration.duration && (
                            <span className="duration">
                                ({t('common:duration')} : {formatDuration(this.props.declaration.duration)})
                            </span>
                        )}
                    </p>
                    {this._canEdit && (
                        <ul className="tools">
                            <li title={t('common:edit')} onClick={this._editDeclaration}>
                                <SVGObject objectId="editIcon" />
                            </li>
                            <li title={t('common:remove')} onClick={this._removeDeclaration}>
                                <SVGObject objectId="crossIco" />
                            </li>
                        </ul>
                    )}
                </span>
                <span className="what">
                    {this.props.declaration.gear && (
                        <div>
                            <h5>{gearName}</h5>
                            {gearDetail && <p>{gearDetail}</p>}
                            {gearInfo && <i>{gearInfo}</i>}
                        </div>
                    )}

                    <ul className="table fourCols">
                        {this.props.declaration.captures.length > 0 ? (
                            this.props.declaration.captures.map((capture, i) => (
                                <li key={i}>
                                    <p>{capture.species.name}</p>
                                    <p>{capture.value > 0 ? formatDeclarationValue(capture.value) : '-'}</p>
                                    <p>{capture.value2 || '-'}</p>
                                    <p>{capture.species.code === 'EL1' && CR}</p>
                                </li>
                            ))
                        ) : (
                            <ul className="table oneCol">
                                <li>
                                    <p>{this.props.declaration.species.name}</p>
                                    <p className="codeRed">Pas de capture</p>
                                    <p />
                                    <p />
                                </li>
                            </ul>
                        )}
                    </ul>
                </span>
            </figure>
        );
    }
}

class DeclarationDay extends React.Component {
    render() {
        return (
            <li>
                <header>
                    <h2>{capitalizeWords(formatLocaleDate(this.props.entry.date, 'dddd D MMMM'))}</h2>
                </header>
                {this.props.entry.declarations.map((decl) => (
                    <DeclarationBlock
                        user={this.props.user}
                        declaration={decl}
                        remove={this.props.remove}
                        afterSave={this.props.afterSave}
                        key={decl.id}
                    />
                ))}
            </li>
        );
    }
}

export class CapturesList extends React.Component {
    render() {
        const entries = [];
        const entriesMap = new Map();
        const declarations = this.props.declarations.slice().reverse();

        declarations.forEach((decl) => {
            const dayKey = formatLocaleDate(decl.eventDate, 'yyyy-MM-dd');
            let entry = entriesMap.get(dayKey);
            if (!entry) {
                entry = { key: dayKey, date: decl.eventDate, declarations: [] };
                entriesMap.set(dayKey, entry);
                entries.push(entry);
            }

            const parent = entry.declarations.find((d) => d.id === decl.parentId);
            if (parent) {
                if (parent.value > 0 || parent.value2 > 0) {
                    parent.captures.push(decl);
                }
            } else {
                decl.captures = [];
                if (decl.value > 0 || decl.value2 > 0) {
                    decl.captures.push(decl);
                }

                entry.declarations.push(decl);
            }
        });

        return (
            <ul>
                {entries.reverse().map((entry) => (
                    <DeclarationDay
                        entry={entry}
                        user={this.props.user}
                        remove={this.props.remove}
                        afterSave={this.props.update}
                        key={entry.key}
                    />
                ))}
            </ul>
        );
    }
}
