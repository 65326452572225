import React from 'react';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

export function InfoCardDownload() {
    return (
        <div className="void downloadIco selected">
            <a href="/download/fiche_info_pecheur_comment_declarer_avr2019_vf.pdf" target="__blank">
                <SVGObject objectId="downloadIco" />
            </a>
            <h6>Ouvrir le document PDF</h6>
        </div>
    );
}
