import { types, flow, resolveIdentifier } from 'mobx-state-tree';

import { Gear } from '@STORES/common/gears';
import { Technique } from '@STORES/common/technique';
import { Species } from '@STORES/common/species';
import { LandingPort } from '@STORES/common/landingPort';

import { api } from '@SUPPORT/api';
import { randomId } from '@SUPPORT/utils';

export const Declaration = types
    .model('Declaration', {
        id: types.identifierNumber,
        parentId: types.maybeNull(types.number),
        userId: types.number,
        orgId: types.maybeNull(types.number),
        type: types.enumeration('DeclarationType', ['sms', 'web', 'org', 'api']),
        status: types.enumeration('DeclarationStatus', ['ok', 'error', 'deprecated']),
        eventDate: types.Date,
        creationDate: types.Date,
        value: types.number,
        value2: types.maybeNull(types.number),
        cumulative: types.number,
        duration: types.maybeNull(types.number),
        size: types.maybeNull(types.number),
        price: types.maybeNull(types.number),
        species: types.reference(Species),
        tags: types.maybeNull(types.array(types.string)),
        zoneId: types.maybeNull(types.number),
        zoneCode: types.maybeNull(types.string),
        zoneName: types.maybeNull(types.string),
        landingPort: types.maybeNull(types.reference(LandingPort)),
        gear: types.maybeNull(types.reference(Gear)),
        technique: types.maybeNull(types.reference(Technique)),
        gearInfo: types.maybeNull(types.map(types.number)),
        buyerOrgId: types.maybeNull(types.number),
        buyerOrgName: types.maybeNull(types.string)
    })
    .volatile(() => ({
        isNew: false
    }))
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        gear: snapshot.gearId,
        gearInfo: typeof snapshot.gearInfo === 'string' ? JSON.parse(snapshot.gearInfo) : snapshot.gearInfo,
        technique: snapshot.techniqueId,
        eventDate: typeof snapshot.eventDate === 'string' ? new Date(snapshot.eventDate) : snapshot.eventDate,
        creationDate:
            typeof snapshot.creationDate === 'string' ? new Date(snapshot.creationDate) : snapshot.creationDate
    }))
    .actions((self) => {
        return {
            setNew: () => {
                self.isNew = true;
            },

            hasTag(tag) {
                return self.tags && self.tags.includes(tag);
            },

            deprecate: flow(function* () {
                yield api.deprecateDeclaration(self.userId, self.id);
            })
        };
    });

export const DeclarationList = types.array(Declaration);

export function newEmptyDeclaration(userId, date, species, initialSpeciesCode) {
    const declaration = Declaration.create({
        id: randomId(),
        type: 'web',
        status: 'ok',
        userId: userId,
        eventDate: date,
        creationDate: date,
        value: 0,
        value2: 0,
        cumulative: 0,
        duration: 0,
        species: resolveIdentifier(Species, species, initialSpeciesCode),
        tags: null,
        zoneId: null,
        zoneCode: null,
        zoneName: null,
        buyerOrgId: null,
        buyerOrgName: null
    });

    declaration.setNew();

    return declaration;
}
