import React from 'react';
import { observe } from 'mobx';
import { inject } from 'mobx-react';

@inject('store')
export class OrgChangeObserver extends React.Component {
    _unobserveOrgChange = null;

    componentDidMount() {
        this._unobserveOrgChange = observe(this.props.store.session.info, 'org', this.props.onChange, true);
    }

    componentWillUnmount() {
        this._unobserveOrgChange();
    }

    render() {
        return this.props.children;
    }
}
