import { types, getRoot, flow } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

import { User } from './user';
import { Declaration } from './declaration';

export const DeclarationWizardStore = types
    .model('DeclarationWizard', {
        show: false,
        rootDeclaration: types.maybeNull(types.reference(Declaration)),
        captures: types.maybeNull(types.array(types.reference(Declaration))),
        date: types.maybeNull(types.Date),
        user: types.maybeNull(types.reference(User))
    })
    .volatile(() => {
        return {
            afterSave: null,
            loadingGroup: false
        };
    })
    .actions((self) => {
        const { app } = getRoot(self);
        return {
            showForDeclaration: flow(function* (user, declaration, afterSave = null) {
                if (self.loadingGroup) {
                    return;
                }

                app.setBusy();
                self.loadingGroup = true;
                try {
                    const response = yield api.getDeclarationGroup(user.id, declaration.parentId);
                    const declarations = response.data;
                    const rootDeclaration = declarations.find((d) => d.id === d.parentId);
                    const captures = declarations.filter((d) => d.id !== declaration.parentId);

                    self.rootDeclaration = rootDeclaration.id;
                    self.captures = captures.map((c) => c.id);
                    self.user = user;
                    self.show = true;
                    self.afterSave = afterSave;
                } finally {
                    app.setModal(true);
                    app.setBusy(false);
                    self.loadingGroup = false;
                }
            }),

            showForNewDeclaration: (user, date, afterSave = null) => {
                self.rootDeclaration = null;
                self.captures = null;
                self.user = user;
                self.date = date;
                self.show = true;
                self.afterSave = afterSave;
                app.setModal(true);
            },

            dismiss: () => {
                self.rootDeclaration = null;
                self.captures = null;
                self.user = null;
                self.date = null;
                self.afterSave = null;
                self.show = false;
                app.setModal(false);
            }
        };
    });
