import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { formatLocaleDate, capitalize } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@inject('store')
@withTranslation(['user'])
@observer
export class DeclarationsValidationDialog extends React.Component {
    render() {
        if (!this.props.store.session.info) {
            return null;
        }

        const { user } = this.props.store.session.info;

        const orgsWithoutDeclaration = new Map();
        const orgsWithDeclarations = new Map();
        const { allOrgs } = user;
        if (!allOrgs) {
            return null;
        }

        if (this.props.store.showValidationDialog) {
            allOrgs.forEach((org) => orgsWithoutDeclaration.set(org, 0));
            this.props.store.declarationsCalendar.forEach((decl) => {
                const org = allOrgs.find((o) => o.id === decl.orgId);
                if (!org) {
                    return;
                }

                if (!orgsWithDeclarations.has(org)) {
                    orgsWithDeclarations.set(org, 0);
                }
                if (orgsWithoutDeclaration.has(org)) {
                    orgsWithoutDeclaration.delete(org);
                }

                orgsWithDeclarations.set(org, orgsWithDeclarations.get(org) + 1);
            });
        }

        const { t } = this.props;

        return (
            <div className={classNames('modal', { show: this.props.store.showValidationDialog })}>
                <div className="sheet">
                    <div className="title">
                        <h3>
                            Validation des déclarations:{' '}
                            {capitalize(formatLocaleDate(this.props.store.dateReported || new Date(), 'MMMM'))}
                        </h3>
                        <SVGObject objectId="exitCross" onClick={this.props.store.stopValidating} />
                    </div>
                    <div className="scroll">
                        <form>
                            <div>
                                <p>
                                    Attention, cette validation est définitive. Vous n’aurez plus la possibilité de
                                    déclarer des captures pour{' '}
                                    {formatLocaleDate(this.props.store.dateReported || new Date(), 'MMMM')}.
                                </p>
                            </div>
                            <div style={{ display: orgsWithDeclarations.size === 0 ? 'none' : 'block' }}>
                                <p>
                                    {t('user:validation-dialog.declarations-locations', {
                                        count: orgsWithDeclarations.size
                                    })}
                                </p>
                            </div>
                            <div style={{ display: orgsWithDeclarations.size === 0 ? 'none' : 'block' }}>
                                <ul>
                                    {Array.from(orgsWithDeclarations.entries()).map((entry) => (
                                        <li key={entry[0].id}>
                                            {entry[0].name} ({entry[1]})
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div style={{ display: orgsWithoutDeclaration.size === 0 ? 'none' : 'block' }}>
                                <p>
                                    {t('user:validation-dialog.no-declarations-locations', {
                                        count: orgsWithoutDeclaration.size
                                    })}
                                </p>
                            </div>
                            <div style={{ display: orgsWithoutDeclaration.size === 0 ? 'none' : 'block' }}>
                                <ul>
                                    {Array.from(orgsWithoutDeclaration.entries()).map((entry) => (
                                        <li key={entry[0].id}>
                                            [{entry[0].code}] {entry[0].name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <p>Souhaitez-vous confirmer la validation des déclarations ?</p>
                            </div>
                        </form>
                    </div>
                    <div className="exit">
                        <div />
                        <div>
                            <button onClick={this.props.store.stopValidating}>Annuler</button>
                            <button onClick={this.props.store.validateDeclarations}>Valider</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
