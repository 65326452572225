import React from 'react';
import { inject } from 'mobx-react';

@inject('store')
export class UnlessUserType extends React.Component {
    render() {
        const types = this.props.type.split(',').map((t) => t.trim());
        if (types.includes(this.props.store.session.info.user.type)) {
            return null;
        }

        return this.props.children;
    }
}
