import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { ResponsiveContainer, ComposedChart, Bar, Line, XAxis, YAxis, Label, CartesianGrid, Tooltip } from 'recharts';

import { formatLocaleDate, formatDeclarationValue } from '@SUPPORT/utils';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@inject('store')
@withTranslation(['common'])
@observer
export class StatisticsSection extends React.Component {
    componentDidMount() {
        this.props.store.stats.updateData();
    }

    downloadCSV = (evt) => {
        evt.preventDefault();
        this.props.store.stats.downloadCSV();
    };

    renderTooltip = (props) => {
        if (!props.payload || props.payload.length === 0) {
            return null;
        }

        const { t } = this.props;
        const { label, payload } = props;
        const fullPayload = payload[0].payload;
        const { user } = this.props.store.session.info;

        return (
            <div className="custom-tooltip">
                <p className="tooltip-title">{formatLocaleDate(label, 'P')}</p>
                {!user.hasTag('pal') && (
                    <React.Fragment>
                        <p>
                            <span className="tooltip-label">{t('common:weight')} :</span>
                            {fullPayload.value > 0 ? formatDeclarationValue(fullPayload.value * 1000) : '-'}
                        </p>
                        <p>
                            <span className="tooltip-label">{t('common:cumulative')} :</span>
                            {fullPayload.cumulative > 0 ? formatDeclarationValue(fullPayload.cumulative * 1000) : '-'}
                        </p>
                    </React.Fragment>
                )}
                {user.isNotRestricted && (
                    <p>
                        <span className="tooltip-label">{t('common:fishermen')} : </span>
                        {fullPayload.personCount}
                    </p>
                )}
                {user.isNotRestricted && (
                    <p>
                        <span className="tooltip-label">
                            {user.hasTag('pal') ? t('common:catches') : t('common:declarations')} :{' '}
                        </span>
                        {fullPayload.declarationCount}
                    </p>
                )}
            </div>
        );
    };

    render() {
        const { t, store } = this.props;
        const { user } = store.session.info;

        return (
            <OrgChangeObserver onChange={this.props.store.stats.updateData}>
                <div className="selected">
                    <div className="header">
                        <h3>{t('common:stats')}</h3>
                        {(user.isNotRestricted || user.type === 'reader') && (
                            <div className="toolkit">
                                <button onClick={this.downloadCSV}>{t('common:download-csv')}</button>
                            </div>
                        )}
                    </div>
                    <div className="" style={{ width: '100%', height: '100%' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <ComposedChart
                                data={store.stats.data.slice()}
                                margin={{ top: 30, right: 40, bottom: 100, left: 30 }}
                            >
                                <CartesianGrid strokeDasharray="1 3" stroke="#999" />
                                <XAxis
                                    dataKey="date"
                                    type="number"
                                    scale="time"
                                    domain={[store.stats.fromDateFilter.valueOf(), store.stats.toDateFilter.valueOf()]}
                                    padding={{ left: 16, right: 16 }}
                                    tickFormatter={(d) => formatLocaleDate(d, 'dd/MM')}
                                    tickMargin={8}
                                />

                                {user.hasTag('pal') ? (
                                    <YAxis
                                        dataKey="declarationCount"
                                        yAxisId="declarationCount"
                                        tickMargin={8}
                                        domain={[0, (dataMax) => Math.floor(dataMax * 1.5)]}
                                        allowDecimals={false}
                                    >
                                        <Label position="insideLeft" angle={-90} offset={0}>
                                            {t('common:catches')}
                                        </Label>
                                    </YAxis>
                                ) : (
                                    <YAxis
                                        dataKey="value"
                                        yAxisId="value"
                                        unit="kg"
                                        domain={[0, (dataMax) => Math.floor(dataMax * 1.1)]}
                                        allowDecimals={false}
                                        allowDataOverflow={true}
                                        tickMargin={8}
                                    >
                                        <Label position="insideLeft" angle={-90} offset={0}>
                                            {t('common:weight')}
                                        </Label>
                                    </YAxis>
                                )}

                                {user.hasTag('pal') ? (
                                    <Bar
                                        dataKey="declarationCount"
                                        yAxisId="declarationCount"
                                        fill="rgba(43, 103, 155, 0.8)"
                                        isAnimationActive={false}
                                    />
                                ) : (
                                    <Bar
                                        dataKey="value"
                                        yAxisId="value"
                                        fill="rgba(43, 103, 155, 0.8)"
                                        isAnimationActive={false}
                                    />
                                )}

                                {user.isNotRestricted && (
                                    <YAxis
                                        dataKey="personCount"
                                        yAxisId="personCount"
                                        orientation="right"
                                        domain={[0, (dataMax) => dataMax * 2]}
                                        allowDecimals={false}
                                    >
                                        <Label position="insideRight" angle={90} offset={18}>
                                            {t('common:fishermen')}
                                        </Label>
                                    </YAxis>
                                )}
                                {user.isNotRestricted && (
                                    <Line
                                        dataKey="personCount"
                                        yAxisId="personCount"
                                        type="monotoneX"
                                        stroke="#dcc23c"
                                        strokeWidth={2}
                                        activeDot={false}
                                        isAnimationActive={false}
                                    />
                                )}
                                <Tooltip
                                    content={this.renderTooltip}
                                    cursor={{ stroke: 'yellow', strokeWidth: 2, strokeDasharray: '1 1' }}
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </OrgChangeObserver>
        );
    }
}
