import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { formatDeclarationValue, formatLocaleDate } from '@SUPPORT/utils';

@withTranslation(['user'])
class CumulativeEntry extends React.Component {
    constructor(props) {
        super(props);
        this._total = { declarationCount: 0, cumulative: { weight: 0, count: 0, restocking: 0, consumption: 0 } };
        this._cumulatives = this.props.entry.cumulatives.slice().sort((c1, c2) => c1.orgName.localeCompare(c2.orgName));
        this._cumulatives.forEach((c) => {
            this._total.declarationCount += c.declarationCount;
            this._total.cumulative['weight'] += c.weightCumulative['value'] || 0;
            this._total.cumulative['count'] += c.countCumulative || 0;
            this._total.cumulative['restocking'] += c.weightCumulative['restocking'] || 0;
            this._total.cumulative['consumption'] += c.weightCumulative['consumption'] || 0;
        });
    }

    render() {
        const { t } = this.props;
        const { startDate, endDate } = this.props.entry;
        let timeSpanInfo = ': ' + t('user:declarations.cumulatives.global-timespan');
        if (startDate && endDate) {
            const formattedStartDate = formatLocaleDate(startDate, 'P');
            const formattedEndDate = formatLocaleDate(endDate, 'P');
            timeSpanInfo = `: ${formattedStartDate} - ${formattedEndDate}`;
        }

        return (
            <li>
                <header>
                    <h2>
                        {this.props.entry.species.name}&nbsp;({this.props.entry.species.code})&nbsp;{timeSpanInfo}
                    </h2>
                </header>
                {this._cumulatives.map((orgEntry) => (
                    <figure key={orgEntry.orgName}>
                        <span className="whenWhere">
                            <p>
                                <span className="zone">{orgEntry.orgName}</span>
                            </p>
                            <p>
                                {t('user:declarations.cumulatives.declarations-count', {
                                    count: orgEntry.declarationCount
                                })}
                            </p>
                        </span>
                        <span className="what">
                            <ul className="tools not-implemented">
                                <li>Plus d&quot;infos</li>
                            </ul>
                            {this.props.entry.species.code === 'EL1' ? (
                                <ul className="table twoCols">
                                    <li>
                                        <p>{t('user:declarations.cumulatives.consumption-cumulative-label')}</p>
                                        <p>{formatDeclarationValue(orgEntry.weightCumulative.consumption)}</p>
                                    </li>
                                    <li>
                                        <p>{t('user:declarations.cumulatives.restocking-cumulative-label')}</p>
                                        <p>{formatDeclarationValue(orgEntry.weightCumulative.restocking)}</p>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="table twoCols">
                                    <li>
                                        <p>{t('user:declarations.cumulatives.weight-cumulative-label')}</p>
                                        <p>{formatDeclarationValue(orgEntry.weightCumulative.value)}</p>
                                    </li>
                                    <li>
                                        <p>{t('user:declarations.cumulatives.count-cumulative-label')}</p>
                                        <p>{orgEntry.countCumulative || '-'}</p>
                                    </li>
                                </ul>
                            )}
                        </span>
                    </figure>
                ))}
                {this._cumulatives.length > 1 ? (
                    <figure>
                        <span className="whenWhere">
                            <p>{t('common:total')}</p>
                            <p className="codeGray">
                                {t('user:declarations.cumulatives.declarations-count', {
                                    count: this._total.declarationCount
                                })}
                            </p>
                        </span>
                        <span className="what">
                            <ul className="tools not-implemented">
                                <li>Plus d&quot;infos</li>
                            </ul>
                            {this.props.entry.species.code === 'EL1' ? (
                                <ul className="table twoCols">
                                    <li>
                                        <p>{t('user:declarations.cumulatives.consumption-cumulative-label')}</p>
                                        <p>
                                            <b>{formatDeclarationValue(this._total.cumulative.consumption)}</b>
                                        </p>
                                    </li>
                                    <li>
                                        <p>{t('user:declarations.cumulatives.restocking-cumulative-label')}</p>
                                        <p>
                                            <b>{formatDeclarationValue(this._total.cumulative.restocking)}</b>
                                        </p>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="table twoCols">
                                    <li>
                                        <p>{t('user:declarations.cumulatives.weight-cumulative-label')}</p>
                                        <p>
                                            <b>{formatDeclarationValue(this._total.cumulative.weight)}</b>
                                        </p>
                                    </li>
                                    <li>
                                        <p>{t('user:declarations.cumulatives.count-cumulative-label')}</p>
                                        <p>
                                            <b>{this._total.cumulative.count || '-'}</b>
                                        </p>
                                    </li>
                                </ul>
                            )}
                        </span>
                    </figure>
                ) : null}
            </li>
        );
    }
}

@inject('store')
@withTranslation(['user'])
@observer
export class CumulativesSection extends React.Component {
    @observable cumulatives = [];

    componentDidMount() {
        this.props.store.listCumulatives().then((cumulatives) => {
            this.cumulatives = cumulatives.slice().sort((c1, c2) => c1.species.name.localeCompare(c2.species.name));
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="styledList selected">
                <div className="header">
                    <h3>
                        {t('user:declarations.cumulatives.title')}
                        <span>{t('user:declarations.cumulatives.subtitle')}</span>
                    </h3>
                </div>
                <div className="scroll">
                    <ul>
                        {this.cumulatives.map((entry) => (
                            <CumulativeEntry entry={entry} key={entry.species.code} />
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}
