import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { parse, isValid, isBefore, isAfter } from 'date-fns';

import { formatLocaleDate } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { UsersPopupMenu } from '@COMPONENTS/common/UsersPopupMenu';
import { ZonesPopupMenu } from '@COMPONENTS/common/ZonesPopupMenu';
import { SpeciesPopupMenu } from '@COMPONENTS/common/SpeciesPopupMenu';
import { GearsPopupMenu } from '@COMPONENTS/common/GearsPopupMenu';
import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';
import { UnlessUserTag } from '@COMPONENTS/common/UnlessUserTag';
import { UnlessUserType } from '@COMPONENTS/common/UnlessUserType';

const INVALID_DATE = new Date(NaN);
const DATE_PATTERN = {
    fr: /(\d\d)\/(\d\d)\/(\d\d\d\d)/
};

@inject('store')
@withTranslation(['common'])
@observer
export class StatisticsFiltersSelector extends React.Component {
    @observable seasons = [];

    @observable fromDateField = '';
    @observable fromDateIsValid = true;

    @observable toDateField = '';
    @observable toDateIsValid = true;

    @computed
    get fromDate() {
        const fromDateMatch = this.fromDateField.match(DATE_PATTERN['fr']);
        return fromDateMatch
            ? parse(`${fromDateMatch[3]}-${fromDateMatch[2]}-${fromDateMatch[1]}`, 'yyyy-MM-dd', new Date())
            : INVALID_DATE;
    }

    @computed
    get toDate() {
        const toDateMatch = this.toDateField.match(DATE_PATTERN['fr']);
        return toDateMatch
            ? parse(`${toDateMatch[3]}-${toDateMatch[2]}-${toDateMatch[1]}`, 'yyyy-MM-dd', new Date())
            : INVALID_DATE;
    }

    componentDidMount() {
        this.syncDateFields();
        this.syncSeasons();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.store.session.info.org.id !== this.props.store.session.info.org.id) {
            this.onResetFilters();
        }
    }

    onUserFilterChange = (evt) => {
        const { stats } = this.props.store;
        const value = evt.target.value === '***' ? evt.target.value : Number(evt.target.value);
        stats.setUserFilter(value);
        stats.updateData();
    };

    onSpeciesFilterChange = (evt) => {
        const { stats } = this.props.store;
        stats.setSpeciesFilter(evt.target.value);
        this.syncSeasons();
        stats.updateData();
    };

    onZoneFilterChange = (evt) => {
        const { stats } = this.props.store;
        const value = evt.target.value === '***' ? evt.target.value : Number(evt.target.value);
        stats.setZoneFilter(value);
        stats.updateData();
    };

    onGearFilterChange = (evt) => {
        const { stats } = this.props.store;
        const value = evt.target.value === '***' ? evt.target.value : Number(evt.target.value);
        stats.setGearFilter(value);
        stats.updateData();
    };

    onTimeSpanFilterChange = (evt) => {
        const { stats } = this.props.store;
        if (evt.target.value.startsWith('season:')) {
            stats.setTimeSpanFilter(evt.target.value);

            const seasonId = stats.timeSpanFilter.seasonId;
            const season = this.seasons.find((s) => s.id === seasonId);
            if (season) {
                stats.setDateFilters(season.startDate, season.endDate);
                stats.updateData();
                this.syncDateFields();
            }
        } else {
            stats.setTimeSpanFilter(evt.target.value);
            if (stats.timeSpanFilter.code !== 'custom') {
                this.syncDateFields();
                stats.updateData();
            }
        }
    };

    onFromDateChange = (evt) => {
        this.fromDateField = evt.target.value;
        this.checkDateFields();
    };

    onToDateChange = (evt) => {
        this.toDateField = evt.target.value;
        this.checkDateFields();
    };

    onDateKey = (evt) => {
        if (this.fromDateIsValid && this.toDateIsValid && evt.key === 'Enter') {
            const { stats } = this.props.store;
            stats.setDateFilters(this.fromDate, this.toDate);
            stats.updateData();
        }
    };

    onResetFilters = () => {
        const { stats } = this.props.store;
        stats.resetFilters();
        stats.updateData();

        this.syncDateFields();
    };

    checkDateFields = () => {
        this.fromDateIsValid = isValid(this.fromDate) && isBefore(this.fromDate, this.toDate);
        this.toDateIsValid = isValid(this.toDate) && isAfter(this.toDate, this.fromDate);
    };

    syncDateFields = () => {
        const { stats } = this.props.store;
        this.fromDateField = formatLocaleDate(stats.fromDateFilter, 'P');
        this.toDateField = formatLocaleDate(stats.toDateFilter, 'P');
        this.checkDateFields();
    };

    syncSeasons = () => {
        const { seasons, stats } = this.props.store;
        const { speciesFilter } = this.props.store.stats;
        if (speciesFilter.code === '***') {
            this.seasons = [];
        } else if (seasons) {
            seasons
                .listForSpecies(speciesFilter.code, true)
                .then((s) => (this.seasons = s.sort((s1, s2) => s1.startDate - s2.startDate)));
        }

        const { user } = this.props.store.session.info;
        stats.setTimeSpanFilter(user.hasTag('dpma') ? 'last-month' : 'this-month');
    };

    render() {
        const { t, store } = this.props;
        const { user } = store.session.info;

        return (
            <OrgChangeObserver onChange={this.onResetFilters}>
                <div className="list subList show">
                    <dl className="show">
                        <div className="burger">
                            <span />
                        </div>
                        <h6>{t('common:filters')}</h6>
                        <dt>
                            {t('common:filters')}
                            <span title={t('admin:users-selector.reset-parameters')}>
                                <SVGObject objectId="resetIco" onClick={this.onResetFilters} />
                            </span>
                        </dt>
                        {user.isNotRestricted && (
                            <dd className="noSelection">
                                <UsersPopupMenu
                                    allowAllUsers
                                    selectProps={{
                                        value: store.stats.userFilter ? store.stats.userFilter.id : '***',
                                        onChange: this.onUserFilterChange
                                    }}
                                />
                            </dd>
                        )}
                        <dd className="noSelection">
                            <SpeciesPopupMenu
                                user={store.session.info.user}
                                allowCatchAllSpecies
                                selectProps={{
                                    value: store.stats.speciesFilter.code,
                                    onChange: this.onSpeciesFilterChange
                                }}
                            />
                        </dd>
                        <dd className="noSelection">
                            <ZonesPopupMenu
                                user={store.session.info.user}
                                selectCallback={(zone) => store.stats.setZoneFilter(zone.zoneId)}
                                allowCatchAllZone
                                selectProps={{
                                    value: store.stats.zoneFilter ? store.stats.zoneFilter.id : '***',
                                    onChange: this.onZoneFilterChange
                                }}
                            />
                        </dd>
                        {user.isNotRestricted && (
                            <dd className="noSelection">
                                <GearsPopupMenu
                                    user={store.session.info.user}
                                    allowCatchAllGear
                                    selectProps={{
                                        value: store.stats.gearFilter ? store.stats.gearFilter.id : '***',
                                        onChange: this.onGearFilterChange
                                    }}
                                />
                            </dd>
                        )}
                        <dd className="noSelection">
                            <select value={store.stats.timeSpanFilter.fullCode} onChange={this.onTimeSpanFilterChange}>
                                {this.seasons.length > 0 ? (
                                    <React.Fragment>
                                        <optgroup label={t('common:campaigns') + ' :'}>
                                            {this.seasons.map((s) => (
                                                <option value={`season:${s.id}`} key={s.id}>
                                                    {s.name}
                                                </option>
                                            ))}
                                        </optgroup>
                                        <optgroup label={t('common:periods') + ' :'}>
                                            <option value="this-week">{t('common:this-week')}</option>
                                            <option value="last-week">{t('common:last-week')}</option>
                                            <UnlessUserTag tag="dpma">
                                                <option value="this-month">{t('common:this-month')}</option>
                                            </UnlessUserTag>
                                            <option value="last-month">{t('common:last-month')}</option>
                                            <option value="this-year">{t('common:this-year')}</option>
                                            <option value="last-year">{t('common:last-year')}</option>
                                            <UnlessUserType type="reader">
                                                <option value="custom">{t('common:filter-period')}...</option>
                                            </UnlessUserType>
                                        </optgroup>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <option value="this-week">{t('common:this-week')}</option>
                                        <option value="last-week">{t('common:last-week')}</option>
                                        <UnlessUserTag tag="dpma">
                                            <option value="this-month">{t('common:this-month')}</option>
                                        </UnlessUserTag>
                                        <option value="last-month">{t('common:last-month')}</option>
                                        <option value="this-year">{t('common:this-year')}</option>
                                        <option value="last-year">{t('common:last-year')}</option>
                                        <UnlessUserType type="reader">
                                            <option value="custom">{t('common:filter-period')}...</option>
                                        </UnlessUserType>
                                    </React.Fragment>
                                )}
                            </select>
                        </dd>
                        <UnlessUserType type="reader">
                            <dd className="noSelection aligned">
                                <label>{t('common:from-date')}</label>
                                <input
                                    className={classNames('date', {
                                        codeRed: !this.fromDateIsValid
                                    })}
                                    type="text"
                                    placeholder="dd/mm/yyyy"
                                    value={this.fromDateField}
                                    onKeyPress={this.onDateKey}
                                    onChange={
                                        store.stats.timeSpanFilter.code === 'custom' ? this.onFromDateChange : null
                                    }
                                    readOnly={store.stats.timeSpanFilter.code !== 'custom'}
                                />
                            </dd>
                            <dd className="noSelection aligned">
                                <label>{t('common:to-date')}</label>
                                <input
                                    className={classNames('date', {
                                        codeRed: !this.toDateIsValid
                                    })}
                                    type="text"
                                    placeholder="dd/mm/yyyy"
                                    value={this.toDateField}
                                    onKeyPress={this.onDateKey}
                                    onChange={store.stats.timeSpanFilter.code === 'custom' ? this.onToDateChange : null}
                                    readOnly={store.stats.timeSpanFilter.code !== 'custom'}
                                />
                            </dd>
                        </UnlessUserType>
                    </dl>
                    <ul className="toolbar" />
                </div>
            </OrgChangeObserver>
        );
    }
}
