import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@inject('store')
@observer
export class AlertDialog extends React.Component {
    render() {
        const { app } = this.props.store;
        return (
            <div className={classNames('modal', { show: app.showAlert })}>
                <div className="sheet">
                    <div className="title">
                        <h3>{app.alertTitle}</h3>
                        <SVGObject objectId="exitCross" onClick={app.cancelAlertCallback || app.dismissAlert} />
                    </div>

                    <div className="scroll">
                        <form>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: app.alertMessage1 }} />
                            </div>
                            {app.alertMessage2 && (
                                <div>
                                    <p dangerouslySetInnerHTML={{ __html: app.alertMessage2 }} />
                                </div>
                            )}
                        </form>
                    </div>

                    <div className="exit">
                        <div />
                        <div>
                            <button
                                disabled={!app.allowAlertActions}
                                onClick={app.cancelAlertCallback || app.dismissAlert}
                            >
                                {app.alertBtn2Label}
                            </button>
                            <button disabled={!app.allowAlertActions} onClick={app.alertCallback || app.dismissAlert}>
                                {app.alertBtn1Label}
                            </button>
                            {app.alertBtn0Label && (
                                <button disabled={!app.allowAlertActions} onClick={app.alert0Callback}>
                                    {app.alertBtn0Label}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
