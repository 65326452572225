import React from 'react';
import { observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

@inject('store')
@withTranslation(['common'])
@observer
export class SpeciesPopupMenu extends React.Component {
    @observable loading = true;
    @observable last = null;

    componentDidMount() {
        this._update();
    }

    _update = () => {
        this.loading = true;
        this.props.user
            .listLastDeclarationSpecies()
            .then((species) => {
                this.last = species;
                this.loading = false;
            })
            .catch(() => (this.loading = false));
    };

    render() {
        const { t, user, species, allowCatchAllSpecies } = this.props;

        let options;
        if (this.loading) {
            options = [
                <option value="" key="loading">
                    {t('common:loading')}...
                </option>
            ];
        } else {
            if (allowCatchAllSpecies) {
                options = [
                    <option value="***" key="catchall">
                        {t('common:all-species')}
                    </option>
                ];
            } else {
                options = [
                    <option value="" key="empty">
                        {t('common:select')}...
                    </option>
                ];
            }

            let speciesList;
            if (species) {
                speciesList = species;
            } else {
                speciesList = this.props.store.species.orgList;
                if (speciesList.length === 0) {
                    speciesList = this.props.store.species.withAnyTag(user.tags);
                }
            }

            const fullList = speciesList.map((sp) => (
                <option value={sp.code} key={sp.code}>
                    {sp.name} {sp.code && `[${sp.code}]`}
                </option>
            ));

            if (this.last && this.last.length > 0) {
                // Check if last species actually exists in the full list first
                const filteredLast = this.last.filter(
                    (code) => !!this.props.store.species.list.find((sp) => sp.code === code)
                );
                const lastList = filteredLast.map((code) => {
                    const sp = this.props.store.species.byCode(code);
                    return (
                        <option value={sp.code} key={sp.code}>
                            {sp.name} {sp.code && `[${sp.code}]`}
                        </option>
                    );
                });

                options.push(
                    <optgroup label={t('common:declaration-dialog.last-declared-species') + ' :'} key="last">
                        {lastList}
                    </optgroup>
                );
                options.push(
                    <optgroup label={t('common:all-species') + ' :'} key="all">
                        {fullList}
                    </optgroup>
                );
            } else {
                options = options.concat(fullList);
            }
        }

        return (
            <OrgChangeObserver onChange={this._update}>
                <select {...this.props.selectProps}>{options}</select>
            </OrgChangeObserver>
        );
    }
}
