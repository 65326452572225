import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { startOfMonth, endOfMonth } from 'date-fns';

import { i18n } from '@SUPPORT/i18n';
import { canDeclare } from '@SUPPORT/rules';
import { formatLocaleDate, capitalize } from '@SUPPORT/utils';

import { newEmptyDeclaration } from '@STORES/common/declaration';

import { WithUserTag } from '@COMPONENTS/common/WithUserTag';
import { CapturesList } from '@COMPONENTS/common/CapturesList';
import { DeclarationsList } from '@COMPONENTS/common/DeclarationsList';

@inject('store')
@withTranslation(['user'])
@observer
export class ReportSection extends React.Component {
    @observable selectFishingType = false;

    componentDidMount() {
        this._update();
    }

    componentWillUnmount() {
        this.props.store.stopReporting();
    }

    _toggleSelectFishingType = () => {
        this.selectFishingType = !this.selectFishingType;
    };

    _closeFishingTypeSelector = () => {
        this.selectFishingType = false;
    };

    _addDeclaration = () => {
        const tags = this.props.store.session.info.user.pickTags(['pap', 'pfp', 'pep', 'civ']);
        if (tags.length <= 1) {
            this._startNewDeclarationDialog(tags[0] || '');
        } else {
            this._toggleSelectFishingType();
        }
    };

    _startNewDeclarationDialog = (tag, suggestedSpecies = null) => {
        const forceDialog = this.props.store.session.info.org.hasFeature('declaration_dialog');
        if ((tag === 'pfp' || tag === 'pep') && !forceDialog) {
            this.props.store.declarationWizard.showForNewDeclaration(
                this.props.store.session.info.user,
                this.props.store.dateReported,
                this._update,
                suggestedSpecies
            );
        } else {
            const defaultSpecies = this.props.store.session.info.user.species[0] || this.props.store.species.orgList[0];
            const speciesCode = suggestedSpecies || (defaultSpecies ? defaultSpecies.code : 'EL1');
            const declaration = newEmptyDeclaration(
                this.props.store.session.info.user.id,
                this.props.store.dateReported,
                this.props.store.species.list,
                speciesCode
            );
            this.props.store.pushDeclaration(declaration);
            this.props.store.declarationDialog.showForDeclaration(
                this.props.store.session.info.user,
                declaration,
                this._update
            );
        }

        this._closeFishingTypeSelector();
    };

    _validateDeclarations = (evt) => {
        evt.preventDefault();
        this.props.store.startValidating();
    };

    _printDeclarationCard = (evt) => {
        evt.preventDefault();

        const since = startOfMonth(this.props.store.dateReported).toISOString();
        const until = endOfMonth(this.props.store.dateReported).toISOString();
        window.open(`/declarations_card?since=${since}&until=${until}`, '_blank');
    };

    _update = () => {
        this.props.store.getFullDeclarationCalendar(this.props.store.dateReported);
    };

    _removeDeclaration = (declaration) => {
        this.props.store.app.alert({
            title: i18n.t('user:declarations.report.remove-alert.title'),
            message1: i18n.t('user:declarations.report.remove-alert.line1'),
            message2: i18n.t('user:declarations.report.remove-alert.line2'),
            label1: i18n.t('common:remove'),

            validationCallback: () => {
                this.props.store.app.setBusy();
                declaration.deprecate().then(() => {
                    this.props.store.removeDeclaration(declaration);
                    this.props.store.app.dismissAlert();
                    this.props.store.app.setBusy(false);
                });
            }
        });
    };

    _dismiss = (evt) => {
        evt.preventDefault();
        this.props.store.stopReporting();
    };

    render() {
        const { user } = this.props.store.session.info;
        const month = this.props.store.dateReported.getMonth() + 1;
        const allowDeclaration =
            canDeclare(user, this.props.store.dateReported) && !this.props.store.hasValidatedDeclarations(month);

        const declarations = this.props.store.declarationsCalendar.filter((decl) => !decl.isNew);

        return (
            <div
                id="reportView"
                className={classNames('selected', {
                    styledList: user.hasTag('pfp')
                })}
            >
                <div className="header row">
                    <button onClick={this._dismiss}>
                        {i18n.t('user:declarations.report.back-to-calendar-button')}
                    </button>
                    <div />
                    <h3>{capitalize(formatLocaleDate(this.props.store.dateReported, 'MMMM yyyy'))}</h3>
                    <div />
                </div>
                <div className="scroll">
                    {user.hasTag('pfp') ? (
                        <CapturesList
                            user={user}
                            declarations={declarations}
                            update={this._update}
                            remove={this._removeDeclaration}
                        />
                    ) : (
                        <DeclarationsList user={user} declarations={declarations} update={this._update} />
                    )}
                </div>
                <div className="exit">
                    {!user.hasOnlyTag('pal') && (
                        <div>
                            <button className="withPopup" onClick={this._addDeclaration} disabled={!allowDeclaration}>
                                {i18n.t('user:declarations.report.new-declaration-button')}
                            </button>

                            <ul
                                className={classNames('south', { show: this.selectFishingType })}
                                style={{ transform: 'translate(-30px, 0)' }}
                            >
                                <li className="title">Type de pêche</li>
                                {user.hasTag('pep') && (
                                    <li onClick={() => this._startNewDeclarationDialog('pep')}>
                                        <label>Pêche embarquée</label>
                                    </li>
                                )}
                                {user.hasTag('pap') && (
                                    <li onClick={() => this._startNewDeclarationDialog('pap')}>
                                        <label>Pêche à pied</label>
                                    </li>
                                )}
                                {user.hasTag('pfp') && (
                                    <li onClick={() => this._startNewDeclarationDialog('pfp')}>
                                        <label>Pêche fluviale pro</label>
                                    </li>
                                )}
                                {user.hasTag('civ') && (
                                    <li onClick={() => this._startNewDeclarationDialog('civ', 'EL1')}>
                                        <label>Civelle</label>
                                    </li>
                                )}
                            </ul>

                            <WithUserTag tag="pap">
                                <button
                                    className="codeOrange"
                                    onClick={this._validateDeclarations}
                                    disabled={this.props.store.hasValidatedDeclarations(month)}
                                >
                                    {i18n.t('user:declarations.report.validate-button')}
                                </button>
                                <button className="codeOrange" onClick={this._printDeclarationCard}>
                                    {i18n.t('user:declarations.report.print-report-button')}
                                </button>
                            </WithUserTag>
                        </div>
                    )}
                    <div />
                </div>
            </div>
        );
    }
}
