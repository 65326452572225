import React from 'react';
import { inject } from 'mobx-react';

@inject('store')
export class WithUserTag extends React.Component {
    render() {
        const included = this.props.tag.split(',').map((tag) => tag.trim());
        const hasTag = this.props.store.session.info.user.hasAnyOfTag(included);
        const excluded = (this.props.unless || '').split(',').map((tag) => tag.trim());
        const hasExcluded = this.props.store.session.info.user.hasAnyOfTag(excluded);
        if (!hasTag || hasExcluded) {
            return null;
        }

        return this.props.children;
    }
}
