import { types, getRoot } from 'mobx-state-tree';

import { User } from './user';
import { Declaration } from './declaration';

export const DeclarationDialogStore = types
    .model('DeclarationDialog', {
        show: false,
        user: types.maybeNull(types.reference(User)),
        declaration: types.maybeNull(types.reference(Declaration))
    })
    .volatile(() => {
        return {
            afterSave: null
        };
    })
    .actions((self) => {
        const { app } = getRoot(self);
        return {
            showForDeclaration: (user, declaration, afterSave = null) => {
                self.declaration = declaration;
                self.user = user;
                self.show = true;
                self.afterSave = afterSave;
                app.setModal(true);
            },

            dismiss: () => {
                self.declaration = null;
                self.user = null;
                self.show = false;
                self.afterSave = null;
                app.setModal(false);
            }
        };
    });
