import React from 'react';
import classNames from 'classnames';
import ReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { observable, action, computed, reaction } from 'mobx';
import { addSeconds, isBefore, endOfToday, isSameMonth } from 'date-fns';

import Transition from 'react-transition-group/Transition';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import { api } from '@SUPPORT/api';
import { rawValidator } from '@SUPPORT/validator';
import { toISODateString, formatLocaleDate, padLeft, capitalize, parseFloatingNum } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

import { GearsPopupMenu } from './GearsPopupMenu';
import { SpeciesPopupMenu } from './SpeciesPopupMenu';
import { ZonesPopupMenu } from './ZonesPopupMenu';

@inject('store')
@withTranslation(['common'])
@observer
class CaptureSheet extends React.Component {
    componentDidUpdate() {
        this.props.capture.form.validate();
    }

    render() {
        const { t } = this.props;
        return (
            <ul
                className={classNames('reportSheet2', {
                    left: this.props.captureIndex + 1 < this.props.activeCaptureIndex,
                    right:
                        ((this.props.state === 'exited' || this.props.state === 'entering') &&
                            this.props.captureIndex + 1 === this.props.activeCaptureIndex) ||
                        this.props.captureIndex + 1 > this.props.activeCaptureIndex
                })}
            >
                <li className="required">
                    <label>
                        {t('common:species')} {this.props.activeCaptureIndex}/{this.props.capturesCount} :
                    </label>
                    <SpeciesPopupMenu
                        user={this.props.store.declarationWizard.user}
                        species={this.props.species}
                        selectProps={this.props.capture.form.select('species').bind()}
                    />
                </li>
                <li>
                    <label>{t('common:weight')} :</label>
                    <input {...this.props.capture.form.select('weight').bind()} className="short" />
                    <p>
                        {this.props.store.species.orgUnits.get(this.props.capture.form.select('species').value) || 'kg'}
                    </p>
                </li>
                {this.props.capture.form.select('species').value !== 'EL1' && (
                    <li>
                        <label>{t('common:count')} :</label>
                        <input {...this.props.capture.form.select('count').bind()} className="short" />
                    </li>
                )}
                {this.props.capture.form.select('species').value === 'EL1' && (
                    <li>
                        <label />
                        <input {...this.props.capture.form.select('restocking').bind()} />
                        <label htmlFor={this.props.capture.form.select('restocking').id}>
                            {t('common:restocking')}
                        </label>
                    </li>
                )}
                <li>
                    <label />
                    <button className="codeOrange" onClick={this.props.capture.remove}>
                        Supprimer cette capture
                    </button>
                </li>{' '}
            </ul>
        );
    }
}

@inject('store')
@withTranslation(['common'])
@observer
export class DeclarationWizard extends React.Component {
    @observable captures = [];
    @observable activeCaptureIndex = 0;
    @observable busy = false;

    constructor(props) {
        super(props);
        this.form = new ReactForm(
            {
                fields: {
                    date: { type: 'text', rules: 'required_without:day|declDate', placeholder: 'jj/mm/aaaa' },
                    day: { type: 'text', rules: 'required_without:date|fdayWzrd', placeholder: 'jj' },
                    durationH: { rules: 'numeric' },
                    durationMn: { rules: 'numeric', placeholder: '00' },
                    zone: { rules: 'required' },
                    gear: { rules: 'required' },
                    species: { rules: 'required' },
                    noCapture: { type: 'checkbox' },
                    gearInfo: { fields: {} }
                }
            },
            {
                plugins: {
                    dvr: dvr({
                        package: rawValidator,
                        extend: ({ validator }) => {
                            validator.register(
                                'fdayWzrd',
                                (value) => {
                                    const referenceDate = this.props.rootDeclaration
                                        ? this.props.rootDeclaration.eventDate
                                        : props.store.declarationWizard.date;
                                    if (!referenceDate) {
                                        return false;
                                    }

                                    const paddedValue = value.length === 1 ? `0${value}` : value;
                                    const date = Date.parse(
                                        `${formatLocaleDate(referenceDate, 'yyyy-MM')}-${paddedValue}T13:00:00`
                                    );

                                    return isSameMonth(date, referenceDate) && isBefore(date, endOfToday());
                                },
                                'Invalid day'
                            );
                        }
                    })
                },
                options: { validateOnChange: true }
            }
        );

        reaction(
            () => props.store.dateReported,
            () => {
                if (props.store.dateReported) {
                    this.form.select('day').set('value', formatLocaleDate(props.store.dateReported, 'dd'));
                    this.form.select('day').focus();
                }
            }
        );

        this.form.select('day').observe(({ form, field }) => {
            const paddedValue = field.value.length === 1 ? `0${field.value}` : field.value;
            if (paddedValue === '') {
                form.select('date').set('value', '');
            } else {
                const fullDate = Date.parse(
                    `${formatLocaleDate(props.store.dateReported, 'yyyy-MM')}-${paddedValue}T13:00:00`
                );
                form.select('date').set('value', formatLocaleDate(fullDate, 'P'));
            }
        });

        this.form.select('gear').observe(({ form, field }) => {
            const selectedGearId = parseInt(field.value, 10);
            const selectedGear = this.props.store.gears.getBy('id', selectedGearId);
            this._syncGearInfoField(selectedGear, 'lengthInfo');
            this._syncGearInfoField(selectedGear, 'surfaceInfo');
            this._syncGearInfoField(selectedGear, 'meshInfo');
            this._syncGearInfoField(selectedGear, 'numHooksInfo');
            this._syncGearInfoField(selectedGear, 'numGearsInfo');
            form.validate();
        });
    }

    _syncGearInfoField = (gear, key) => {
        const gearInfo = this.form.select('gearInfo');
        if (gear && gear[key]) {
            const initialValue =
                this.props.rootDeclaration &&
                this.props.rootDeclaration.gearInfo &&
                this.props.rootDeclaration.gearInfo.get(key)
                    ? this.props.rootDeclaration.gearInfo.get(key)
                    : '';

            gearInfo.add({
                key: key,
                type: 'text',
                rules: 'numeric|required',
                value: initialValue
            });
        } else if (gearInfo.has(key)) {
            gearInfo.del(key);
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.rootDeclaration && prevProps.rootDeclaration !== this.props.rootDeclaration) {
            const day = formatLocaleDate(this.props.rootDeclaration.eventDate, 'dd');
            const date = formatLocaleDate(this.props.rootDeclaration.eventDate, 'P');

            this.form.select('day').set('value', day);
            this.form.select('date').set('value', date);
            this.form.select('zone').set('value', this.props.rootDeclaration.zoneId);
            this.form.select('gear').set('value', this.props.rootDeclaration.gear.id);
            this.form.select('species').set('value', this.props.rootDeclaration.species.code);
            this.form.select('noCapture').set('value', this.props.rootDeclaration.value === -1);

            if (this.props.rootDeclaration.duration) {
                const durationMn = this.props.rootDeclaration.duration % 60;
                this.form.select('durationMn').set('value', padLeft(durationMn, 2, '0'));
                const durationH = (this.props.rootDeclaration.duration - durationMn) / 60;
                this.form.select('durationH').set('value', durationH);
            } else {
                this.form.select('durationH').set('value', '');
                this.form.select('durationMn').set('value', '00');
            }

            this.form.validate();

            this.captures = [];
            let indexOffset = 0;
            if (this.props.rootDeclaration.value !== -1) {
                this.captures.push(this._newCaptureFromDeclaration(this.props.rootDeclaration, 0));
                indexOffset = 1;
            }
            this.props.captures.forEach((decl, i) => {
                this.captures.push(this._newCaptureFromDeclaration(decl, i + indexOffset));
            });
        }
    }

    @computed
    get canGoForward() {
        if (this.captures.length === 0 || this.activeCaptureIndex >= this.captures.length) {
            return false;
        }
        if (this.captures.length > 0 && this.activeCaptureIndex === 0) {
            return true;
        }

        const capture = this.captures[this.activeCaptureIndex - 1];
        return capture && capture.form && capture.form.isValid;
    }

    @computed
    get canGoBackward() {
        if (this.captures.length === 0 || this.activeCaptureIndex === 0) {
            return false;
        }

        const capture = this.captures[this.activeCaptureIndex - 1];
        return capture && capture.form && capture.form.isValid;
    }

    @computed
    get canAddCapture() {
        const allCapturesValid = this.captures.reduce((valid, capture) => valid && capture.form.isValid, true);
        return this.form.isValid && allCapturesValid && !this.busy && !this.form.select('noCapture').value;
    }

    @computed
    get canSave() {
        const allCapturesValid = this.captures.reduce((valid, capture) => valid && capture.form.isValid, true);
        return (
            this.form.isValid &&
            allCapturesValid &&
            !this.busy &&
            (this.captures.length > 0 || this.form.select('noCapture').value)
        );
    }

    @action
    setBusy(busy = true) {
        this.busy = busy;
    }

    @action.bound
    nextCapture(evt) {
        evt.preventDefault();
        if (this.canGoForward) {
            this.activeCaptureIndex += 1;
        }
    }

    @action.bound
    previousCapture(evt) {
        evt.preventDefault();
        if (this.canGoBackward) {
            this.activeCaptureIndex -= 1;
        }
    }

    @computed
    get nextCaptureTitle() {
        if (
            (this.activeCaptureIndex === 0 && this.captures.length === 0) ||
            this.activeCaptureIndex === this.captures.length
        ) {
            return null;
        }

        return this._capturesListTitle(this.activeCaptureIndex, this.captures.length);
    }

    @computed
    get previousCaptureTitle() {
        if (this.activeCaptureIndex === 0) {
            return null;
        }

        if (this.activeCaptureIndex === 1) {
            const { t } = this.props;
            const species = this.props.store.species.byCode(this.form.select('species').value);
            return `${t('common:declaration-wizard.target-species')} : ${species.name}`;
        }

        return this._capturesListTitle(0, this.activeCaptureIndex - 1);
    }

    _capturesListTitle(from, to) {
        const { t } = this.props;
        const lines = [];
        for (let i = from; i < to; i++) {
            const capture = this.captures[i];
            const weight = capture.form.select('weight').value;
            const count = capture.form.select('count').value;

            let value;
            if (!weight && !count) {
                value = '-';
            } else if (weight && !count) {
                value = `${weight} kg`;
            } else if (!weight && count) {
                value = count;
            } else {
                value = `${weight} kg - ${count}`;
            }

            const species = this.props.store.species.byCode(capture.form.select('species').value);
            lines.push(`${species ? species.name : t('common:declaration-wizard.species-not-selected')} : ${value}`);
        }

        return lines.join('\n');
    }

    @action.bound
    addCapture(evt) {
        evt.preventDefault();
        this.captures.push(this._newEmptyCapture(this.captures.length));
        this.activeCaptureIndex = this.captures.length;
    }

    _newEmptyCapture(index, defaultId = null) {
        let id = defaultId || Math.round(Math.random() * 1000000);
        if (this.captures.length === 0 && this.props.rootDeclaration && !defaultId) {
            id = this.props.rootDeclaration.id;
        }

        return {
            id: id,
            form: new ReactForm(
                {
                    fields: {
                        species: {
                            rules: 'required',
                            value: index === 0 ? this.form.select('species').value : ''
                        },
                        weight: { rules: 'required_without:count|fnumeric' },
                        count: { rules: 'required_without:weight|numeric' },
                        restocking: { type: 'checkbox' }
                    }
                },
                {
                    plugins: { dvr: dvr(rawValidator) },
                    options: { validateOnChange: true }
                }
            ),

            remove: (evt) => {
                evt.preventDefault();
                if (this.activeCaptureIndex === this.captures.length) {
                    this.activeCaptureIndex -= 1;
                }
                this.captures = this.captures.filter((c) => c.id !== id);
            }
        };
    }

    _newCaptureFromDeclaration(declaration, index) {
        const capture = this._newEmptyCapture(index, declaration.id);
        const unit = this.props.store.species.orgUnits.get(declaration.species.code) || 'kg';
        const divider = unit === 'kg' ? 1000 : 1;

        capture.parentId = declaration.parentId;
        capture.form.select('species').set('value', declaration.species.code);
        capture.form.select('weight').set('value', declaration.value ? declaration.value / divider : '');
        capture.form.select('count').set('value', declaration.value2 || '');
        capture.form.select('restocking').set('value', declaration.hasTag('restocking'));
        capture.form.validate();
        return capture;
    }

    @action.bound
    reset() {
        this.captures.clear();
        this.activeCaptureIndex = 0;
        this.form.reset();
    }

    save = (evt) => {
        evt.preventDefault();

        const values = this.form.values();
        const date = new Date(toISODateString(values.date));
        const masterDeclaration = {
            type: this.props.store.declarationWizard.user.type === 'admin' ? 'org' : 'web',
            eventDate: date.toISOString(),
            species: values.species,
            zoneId: parseInt(values.zone, 10),
            value: values.noCapture ? -1 : 0,
            value2: null,
            duration: null,
            tags: ['pfp']
        };
        if (this.props.rootDeclaration) {
            masterDeclaration.id = this.props.rootDeclaration.id;
            masterDeclaration.parentId = this.props.rootDeclaration.id;
        }
        if (values.durationH || values.durationMn) {
            masterDeclaration.duration =
                parseInt(values.durationH || '0', 10) * 60 + parseInt(values.durationMn || '0');
            if (masterDeclaration.duration === 0) {
                masterDeclaration.duration = null;
            }
        }
        if (values.gear !== '') {
            masterDeclaration.gearId = parseInt(values.gear, 10);
        }
        if (values.gearInfo.lengthInfo) {
            masterDeclaration.gearInfo = masterDeclaration.gearInfo || {};
            masterDeclaration.gearInfo.lengthInfo = parseInt(values.gearInfo.lengthInfo, 10);
        }
        if (values.gearInfo.surfaceInfo) {
            masterDeclaration.gearInfo = masterDeclaration.gearInfo || {};
            masterDeclaration.gearInfo.surfaceInfo = parseInt(values.gearInfo.surfaceInfo, 10);
        }
        if (values.gearInfo.meshInfo) {
            masterDeclaration.gearInfo = masterDeclaration.gearInfo || {};
            masterDeclaration.gearInfo.meshInfo = parseInt(values.gearInfo.meshInfo, 10);
        }
        if (values.gearInfo.numHooksInfo) {
            masterDeclaration.gearInfo = masterDeclaration.gearInfo || {};
            masterDeclaration.gearInfo.numHooksInfo = parseInt(values.gearInfo.numHooksInfo, 10);
        }
        if (values.gearInfo.numGearsInfo) {
            masterDeclaration.gearInfo = masterDeclaration.gearInfo || {};
            masterDeclaration.gearInfo.numGearsInfo = parseInt(values.gearInfo.numGearsInfo, 10);
        }
        if (masterDeclaration.gearInfo) {
            masterDeclaration.gearInfo = JSON.stringify(masterDeclaration.gearInfo);
        }

        const declarations = [masterDeclaration];
        if (!values.noCapture) {
            this.captures.forEach((capture, i) => {
                const captureValues = capture.form.values();

                let declaration;
                if (i === 0 && captureValues.species === masterDeclaration.species) {
                    declaration = masterDeclaration;
                } else {
                    declaration = { ...masterDeclaration, species: captureValues.species, value: null, value2: null };
                    declaration.eventDate = addSeconds(date, i).toISOString();
                    declarations.push(declaration);
                }

                if (this.props.rootDeclaration) {
                    declaration.id = capture.id;
                    declaration.parentId = masterDeclaration.id;
                }
                if (captureValues.weight) {
                    const unit = this.props.store.species.orgUnits.get(captureValues.species) || 'kg';
                    const multiplier = unit === 'kg' ? 1000 : 1;
                    declaration.value = Math.round(parseFloatingNum(captureValues.weight.toString()) * multiplier);
                }
                if (captureValues.count) {
                    declaration.value2 = parseInt(captureValues.count, 10);
                }
                if (captureValues.species === 'EL1') {
                    if (captureValues.restocking) {
                        if (declaration.tags.includes('consumption')) {
                            declaration.tags.splice(declaration.tags.indexOf('consumption'), 1);
                        }
                        if (!declaration.tags.includes('restocking')) {
                            declaration.tags.push('restocking');
                        }
                    } else {
                        if (declaration.tags.includes('restocking')) {
                            declaration.tags.splice(declaration.tags.indexOf('restocking'), 1);
                        }
                        if (!declaration.tags.includes('consumption')) {
                            declaration.tags.push('consumption');
                        }
                    }
                }
            });
        }

        const promise = this.props.rootDeclaration
            ? api.updateDeclarationGroup(this.props.store.declarationWizard.user.id, declarations)
            : api.createDeclarationGroup(this.props.store.declarationWizard.user.id, declarations);

        promise.then(() => {
            const afterSave = this.props.store.declarationWizard.afterSave;
            this._dismiss();
            if (afterSave) {
                afterSave();
            }
        });
    };

    cancel = (evt) => {
        evt.preventDefault();
        this._dismiss();
    };

    _dismiss = () => {
        this.props.store.declarationWizard.dismiss();
        this.reset();
    };

    _captureSheetSpeciesList(species, sheetIndex) {
        if (sheetIndex === 0) {
            return species;
        }

        // Filter out species that have already been used
        const mainSpecies = this.form.select('species').value;
        const capturedSpecies = this.captures
            .filter((c, i) => i != sheetIndex && c.form.select('species').value !== mainSpecies)
            .map((c) => c.form.select('species').value);
        const usedSpecies = [mainSpecies].concat(capturedSpecies);

        return species.filter((sp) => !usedSpecies.includes(sp.code));
    }

    render() {
        const { user } = this.props.store.declarationWizard;
        if (!user) {
            return null;
        }

        const species = this.props.store.species.orgList;
        const date = this.props.rootDeclaration
            ? this.props.rootDeclaration.eventDate
            : this.props.store.declarationWizard.date;

        const { t } = this.props;
        return (
            <div className={classNames('modal', { show: this.props.store.declarationWizard.show })}>
                <div className="sheet">
                    <div className="title">
                        <h3>
                            {this.props.rootDeclaration
                                ? t('common:declaration-dialog.modify-declaration')
                                : t('common:declaration-dialog.new-declaration')}
                        </h3>
                        <div>
                            <div onClick={this.previousCapture} title={this.previousCaptureTitle}>
                                <SVGObject
                                    className={classNames('backward', { disabled: !this.canGoBackward })}
                                    objectId="arrowIco"
                                />
                            </div>
                            <div onClick={this.nextCapture} title={this.nextCaptureTitle}>
                                <SVGObject
                                    className={classNames('forward', { disabled: !this.canGoForward })}
                                    objectId="arrowIco"
                                />
                            </div>
                            <div onClick={this.cancel}>
                                <SVGObject className="close" objectId="exitCross" />
                            </div>
                        </div>
                    </div>

                    <div className="wizard">
                        <ul className={classNames('reportSheet2', { left: this.activeCaptureIndex > 0 })}>
                            <li className="required">
                                <label>{t('common:date')} :</label>
                                {this.props.withPartialDate ? (
                                    <div>
                                        <input className="twoDigits" {...this.form.select('day').bind()} />
                                        <p>{formatLocaleDate(date, 'MMMM yyyy')}</p>
                                    </div>
                                ) : (
                                    <input className="date" {...this.form.select('date').bind()} />
                                )}
                            </li>

                            <li>
                                <label>{t('common:duration')} :</label>
                                <div>
                                    <input
                                        type="text"
                                        className="twoDigits"
                                        {...this.form.select('durationH').bind()}
                                    />
                                    <p>h</p>
                                    <input
                                        type="text"
                                        className="twoDigits"
                                        {...this.form.select('durationMn').bind()}
                                    />
                                    <p>mn</p>
                                </div>
                            </li>

                            <li className="required">
                                <label>{t(user.hasTag('pfp') ? 'common:fishing-lot' : 'common:zone-full')} :</label>
                                <ZonesPopupMenu
                                    user={user}
                                    selectCallback={(zone) => {
                                        this.form.select('zone').set(zone.zoneId);
                                        this.form.validate();
                                    }}
                                    selectProps={this.form.select('zone').bind()}
                                />
                            </li>

                            <li className="required">
                                <label>{t('common:gear')} :</label>
                                <GearsPopupMenu
                                    user={user}
                                    selectCallback={(gear) => {
                                        this.form.select('gear').set(gear.id);
                                        this.form.validate();
                                    }}
                                    selectProps={this.form.select('gear').bind()}
                                />
                            </li>

                            {this.form.select('gearInfo').has('lengthInfo') && (
                                <li className="required">
                                    <label>{capitalize(t('common:gear-info.length'))} :</label>
                                    <input
                                        {...this.form.select('gearInfo.lengthInfo').bind()}
                                        className="twoDigits"
                                    />{' '}
                                    m
                                </li>
                            )}
                            {this.form.select('gearInfo').has('surfaceInfo') && (
                                <li className="required">
                                    <label>{capitalize(t('common:gear-info.surface'))} :</label>
                                    <input
                                        {...this.form.select('gearInfo.surfaceInfo').bind()}
                                        className="twoDigits"
                                    />{' '}
                                    m<sup>2</sup>
                                </li>
                            )}
                            {this.form.select('gearInfo').has('meshInfo') && (
                                <li className="required">
                                    <label>{capitalize(t('common:gear-info.mesh'))} :</label>
                                    <input {...this.form.select('gearInfo.meshInfo').bind()} className="twoDigits" /> mm
                                </li>
                            )}
                            {this.form.select('gearInfo').has('numHooksInfo') && (
                                <li className="required">
                                    <label>{capitalize(t('common:gear-info.num-hooks'))} :</label>
                                    <input
                                        {...this.form.select('gearInfo.numHooksInfo').bind()}
                                        className="twoDigits"
                                    />
                                </li>
                            )}
                            {this.form.select('gearInfo').has('numGearsInfo') && (
                                <li className="required">
                                    <label>{capitalize(t('common:gear-info.num-gears'))} :</label>
                                    <input
                                        {...this.form.select('gearInfo.numGearsInfo').bind()}
                                        className="twoDigits"
                                    />
                                </li>
                            )}

                            <li className="required">
                                <label>{t('common:declaration-wizard.target-species')} :</label>
                                <SpeciesPopupMenu
                                    user={user}
                                    species={species}
                                    selectProps={this.form.select('species').bind()}
                                />
                            </li>
                            <li>
                                <label />
                                <input {...this.form.select('noCapture').bind()} />
                                <label htmlFor={this.form.select('noCapture').id}>
                                    {t('common:declaration-wizard.no-capture')}
                                </label>
                            </li>
                        </ul>

                        <TransitionGroup component={null}>
                            {this.captures.map((capture, idx) => (
                                <Transition in={true} timeout={1} key={capture.id}>
                                    {(state) => (
                                        <CaptureSheet
                                            state={state}
                                            capture={capture}
                                            captureIndex={idx}
                                            capturesCount={this.captures.length}
                                            activeCaptureIndex={this.activeCaptureIndex}
                                            species={this._captureSheetSpeciesList(species, idx)}
                                        />
                                    )}
                                </Transition>
                            ))}
                        </TransitionGroup>
                    </div>

                    <div className="exit">
                        <div>
                            <button onClick={this.addCapture} disabled={!this.canAddCapture}>
                                {t('common:declaration-wizard.add-capture')}
                            </button>
                        </div>
                        <div>
                            <button onClick={this.cancel}>{t('common:cancel')}</button>
                            <button onClick={this.save} disabled={!this.canSave}>
                                {t('common:save')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
