import { types, flow, getRoot } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

export const Technique = types.model('Technique', {
    id: types.identifierNumber,
    code: types.string,
    name: types.string
});

const TechniquesList = types.array(Technique);

export const TechniquesStore = types
    .model('TechniquesStore', {
        list: TechniquesList
    })
    .views((self) => {
        return {
            getById: (id) => {
                return self.list.find((g) => g.id === id);
            },

            getByCode: (code) => {
                return self.list.find((g) => g.code === code);
            }
        };
    })
    .actions((self) => {
        const { app } = getRoot(self);
        return {
            listAll: flow(function* () {
                app.setBusy();
                try {
                    const response = yield api.listTechniques(app.language);
                    self.list = TechniquesList.create(response.data || []);
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
