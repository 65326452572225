import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

import { Header } from '@COMPONENTS/common/Header';
import { Copyright } from '@COMPONENTS/common/Copyright';
import { ModalBackground } from '@COMPONENTS/common/ModalBackground';

import { CumulativesSection } from '@COMPONENTS/user/declarations/CumulativesSection';
import { CalendarSection } from '@COMPONENTS/user/declarations/CalendarSection';
import { ReportSection } from '@COMPONENTS/user/declarations/ReportSection';
import { InfoSection } from '@COMPONENTS/user/account/InfoSection';
import { PasswordSection } from '@COMPONENTS/user/account/PasswordSection';
import { DeclarationsValidationDialog } from '@COMPONENTS/user/declarations/DeclarationsValidationDialog';
import { InfoCardDownload } from '@COMPONENTS/user/help/InfoCardDownload';

import { ErrorBoundary } from '@COMPONENTS/common/ErrorBoundary';
import { SelectorLink } from '@COMPONENTS/common/SelectorLink';
import { AlertDialog } from '@COMPONENTS/common/AlertDialog';
import { DeclarationDialog } from '@COMPONENTS/common/DeclarationDialog';
import { DeclarationWizard } from '@COMPONENTS/common/DeclarationWizard';
import { StatisticsSection } from '@COMPONENTS/common/StatisticsSection';
import { StatisticsFiltersSelector } from '@COMPONENTS/common/StatisticsFiltersSelector';

@inject('store')
@withTranslation(['user', 'common'])
@observer
export class UserRoot extends React.Component {
    componentDidMount() {
        this.props.store.session.getSession().then(this.props.store.loadInitialData);
    }

    render() {
        const { t } = this.props;
        const { info } = this.props.store.session;
        return (
            <ErrorBoundary>
                <Header />

                {this.props.store.app.isReady ? (
                    <div id="content">
                        <div className="list">
                            <dl className="show">
                                <div className="burger">
                                    <span />
                                </div>
                                <h6>Déclarations</h6>

                                <dt>{t('user:main-selector.catches-header')}</dt>
                                <SelectorLink
                                    label={t('user:main-selector.catches-cumulatives-section')}
                                    to="/cumulatives"
                                />
                                <SelectorLink label={t('user:main-selector.catches-calendar-section')} to="/calendar" />
                                <SelectorLink label={t('common:stats')} to="/stats" />

                                <dt>{t('user:main-selector.account-header')}</dt>
                                <SelectorLink label={t('user:main-selector.account-infos-section')} to="/info" />
                                <SelectorLink
                                    label={t('user:main-selector.account-password-section')}
                                    to="/password"
                                    className="not-implemented"
                                />

                                {info && info.user.hasTag('pap') && (
                                    <React.Fragment>
                                        <dt>{t('common:help')}</dt>
                                        <SelectorLink label={t('user:main-selector.help-card')} to="/help" />
                                    </React.Fragment>
                                )}
                            </dl>
                            <ul className="toolbar" />
                        </div>

                        <Route path="/stats" component={StatisticsFiltersSelector} />

                        <div className="listResult selected">
                            <div className="contentArea">
                                <Route path="/cumulatives" component={CumulativesSection} />
                                <Route
                                    path="/calendar"
                                    component={this.props.store.dateReported ? ReportSection : CalendarSection}
                                />
                                <Route path="/stats" component={StatisticsSection} />
                                <Route path="/info" component={InfoSection} />
                                <Route path="/password" component={PasswordSection} />

                                <Route path="/help" component={InfoCardDownload} />
                            </div>
                            <Copyright key="copyright" />
                        </div>

                        <Route exact path="/" render={() => <Redirect to="/calendar" />} />
                    </div>
                ) : null}

                <ModalBackground display={this.props.store.app.inModal} />
                <DeclarationDialog declaration={this.props.store.declarationDialog.declaration} withPartialDate />
                <DeclarationWizard
                    rootDeclaration={this.props.store.declarationWizard.rootDeclaration}
                    captures={this.props.store.declarationWizard.captures}
                    withPartialDate
                />
                <DeclarationsValidationDialog />
                <AlertDialog />
            </ErrorBoundary>
        );
    }
}
