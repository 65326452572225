import { types, flow } from 'mobx-state-tree';
import { startOfMonth, endOfMonth } from 'date-fns';

import { AppStore } from '@STORES/common/app';
import { SessionStore } from '@STORES/common/session';
import { SpeciesStore } from '@STORES/common/species';
import { BuyerOrgList } from '@STORES/common/buyer-org';
import { ZonesStore } from '@STORES/common/zones';
import { GearsStore } from '@STORES/common/gears';
import { TechniquesStore } from '@STORES/common/technique';
import { StatisticsStore } from '@STORES/common/stats';
import { UserList } from '@STORES/common/user';
import { DeclarationList } from '@STORES/common/declaration';
import { DeclarationDialogStore } from '@STORES/common/declaration.dialog';
import { DeclarationWizardStore } from '@STORES/common/declaration.wizard';
import { LandingPortsStore } from '@STORES/common/landingPort';

import { api } from '@SUPPORT/api';

const UserStore = types
    .model('UserStore', {
        app: types.optional(AppStore, {}),
        session: types.optional(SessionStore, {}),
        species: types.optional(SpeciesStore, {}),
        zones: types.optional(ZonesStore, {}),
        gears: types.optional(GearsStore, {}),
        techniques: types.optional(TechniquesStore, {}),
        stats: types.optional(StatisticsStore, {}),
        landingPorts: types.maybeNull(LandingPortsStore),

        declarationDialog: types.optional(DeclarationDialogStore, {}),
        declarationWizard: types.optional(DeclarationWizardStore, {}),

        calendarYear: types.number,
        calendarValidations: types.array(types.number),
        countsCalendar: types.map(types.map(types.number)),
        declarationsCalendar: types.optional(DeclarationList, []),
        showValidationDialog: types.optional(types.boolean, false),
        dateReported: types.maybeNull(types.Date),
        buyerOrgs: types.maybeNull(BuyerOrgList)
    })
    .views((self) => {
        return {
            hadDeclarationOnDate(date) {
                if (!date) {
                    return false;
                }

                const monthEntry = self.countsCalendar.get(date.getMonth() + 1);
                if (!monthEntry) {
                    return false;
                }

                const dayCount = monthEntry.get(date.getDate());
                if (!dayCount) {
                    return false;
                }

                return dayCount > 0;
            },

            hasValidatedDeclarations(month) {
                return self.calendarValidations.includes(month);
            }
        };
    })
    .actions((self) => {
        return {
            loadInitialData: flow(function* () {
                const { user, org } = yield self.session.getSession();

                if (org && org.hasFeature('landing_ports')) {
                    self.landingPorts = LandingPortsStore.create({});
                    yield self.landingPorts.loadInitialData();
                }

                yield Promise.all([
                    self.species.listAll(),
                    self.species.listOrgSpecies(),
                    self.zones.listAll(),
                    self.gears.listAll(),
                    self.techniques.listAll(),

                    user.listSpecies(),
                    user.listZones(),
                    user.listAllOrgs()
                ]);

                self.app.setReady();
            }),

            listUserOrgAdmins: flow(function* () {
                self.app.setBusy();
                try {
                    const info = yield self.session.getSession();
                    const response = yield api.listUserOrgAdmins(info.user.id);
                    return UserList.create(response.data || []);
                } finally {
                    self.app.setBusy(false);
                }
            }),

            listUserBuyerOrgs: flow(function* () {
                self.app.setBusy();
                try {
                    const info = yield self.session.getSession();
                    const response = yield api.listUserBuyerOrgs(info.user.id);
                    self.buyerOrgs = BuyerOrgList.create(response.data || []);
                    return self.buyerOrgs;
                } finally {
                    self.app.setBusy(false);
                }
            }),

            listCumulatives: flow(function* () {
                self.app.setBusy();
                try {
                    const info = yield self.session.getSession();
                    const response = yield api.listUserCumulatives(info.user.id);
                    return response.data || [];
                } finally {
                    self.app.setBusy(false);
                }
            }),

            jumpToNextYear: () => {
                const currentYear = new Date().getFullYear();
                if (self.calendarYear + 1 === currentYear + 1) {
                    return;
                }

                self.calendarYear += 1;
            },

            jumpToPreviousYear: () => {
                if (self.calendarYear - 1 < 2014) {
                    return;
                }

                self.calendarYear -= 1;
            },

            getDeclarationCalendar: flow(function* (year) {
                self.app.setBusy();
                try {
                    const info = yield self.session.getSession();
                    const response1 = yield api.getDeclarationCalendar(info.user.id, year);
                    self.countsCalendar = response1.data || {};
                    const response2 = yield api.listValidations(info.user.id, year);
                    self.calendarValidations = (response2.data || []).map((v) => v.month);
                } finally {
                    self.app.setBusy(false);
                }
            }),

            getFullDeclarationCalendar: flow(function* (date) {
                self.app.setBusy();
                try {
                    const info = yield self.session.getSession();
                    const startDate = startOfMonth(date);
                    const endDate = endOfMonth(startDate);
                    const response = yield api.listUserDeclarations(info.user.id, {
                        since: startDate.toISOString(),
                        until: endDate.toISOString()
                    });

                    self.declarationsCalendar = DeclarationList.create(response.data || []);
                } finally {
                    self.app.setBusy(false);
                }
            }),

            pushDeclaration: (declaration) => {
                self.declarationsCalendar.push(declaration);
            },

            removeDeclaration: (declaration) => {
                self.declarationsCalendar = self.declarationsCalendar.filter(
                    (decl) => decl.id !== declaration.id && decl.parentId !== declaration.id
                );
            },

            startReportingOnDate: (date) => {
                self.dateReported = date;
            },

            stopReporting: () => {
                self.dateReported = null;
            },

            startValidating: () => {
                self.app.setModal();
                self.showValidationDialog = true;
            },

            stopValidating: () => {
                self.app.setModal(false);
                self.showValidationDialog = false;
            },

            validateDeclarations: flow(function* () {
                self.app.setBusy();
                try {
                    const info = yield self.session.getSession();
                    const year = self.dateReported.getFullYear();
                    const month = self.dateReported.getMonth() + 1;
                    yield api.validateDeclarations(info.user.id, year, month);
                    self.calendarValidations.push(month);
                } finally {
                    self.app.setBusy(false);
                    self.stopValidating();
                }
            })
        };
    });

export const store = UserStore.create({ calendarYear: new Date().getFullYear() });
