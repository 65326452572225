import { types, flow, getRoot, applySnapshot } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

export const Gear = types.model('Gear', {
    id: types.identifierNumber,
    id2: types.maybeNull(types.string),
    id3: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    code2: types.maybeNull(types.string),
    code3: types.maybeNull(types.string),
    code4: types.maybeNull(types.string),
    lengthInfo: types.boolean,
    surfaceInfo: types.boolean,
    meshInfo: types.boolean,
    numHooksInfo: types.boolean,
    numGearsInfo: types.boolean,
    enabled: types.boolean,
    name: types.string,
    tags: types.maybeNull(types.array(types.string))
});

const GearsList = types.array(Gear);

export const GearsStore = types
    .model('GearsStore', {
        list: GearsList
    })
    .views((self) => {
        return {
            getBy: (key, value) => {
                return self.list.find((g) => g[key] === value);
            },

            tagged: (tags) => {
                if (!tags) {
                    return self.list;
                }

                return self.list.filter(
                    (gear) => tags.some((tag) => gear.tags && gear.tags.includes(tag)) && gear.enabled
                );
            }
        };
    })
    .actions((self) => {
        const { app } = getRoot(self);
        return {
            listAll: flow(function* () {
                app.setBusy();
                try {
                    const response = yield api.listGears(app.language);
                    self.list = GearsList.create(response.data || []);
                } finally {
                    app.setBusy(false);
                }
            }),

            listTagged: flow(function* (tag) {
                app.setBusy();
                try {
                    const response = yield api.listTaggedGears(tag, app.language);
                    self.list = GearsList.create(response.data || []);
                } finally {
                    app.setBusy(false);
                }
            }),

            updateGearInfo: flow(function* (gearId, values) {
                app.setBusy();
                try {
                    const response = yield api.updateGear(gearId, values);
                    const gear = self.getBy('id', gearId);
                    applySnapshot(gear, response.data);
                    return gear;
                } finally {
                    app.setBusy(false);
                }
            })
        };
    });
