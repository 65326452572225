import React from 'react';
import classNames from 'classnames';
import { withRouter, matchPath } from 'react-router';

@withRouter
export class SelectorLink extends React.Component {
    _navigate = (evt) => {
        evt.preventDefault();
        this.props.history.push(this.props.to);
    };

    render() {
        let isActive = matchPath(this.props.location.pathname, { path: this.props.to });
        if (this.props.also) {
            isActive = isActive || matchPath(this.props.location.pathname, { path: this.props.also });
        }

        const propsClassName = this.props.className || '';
        const classes = classNames(propsClassName, { selected: isActive });
        const Tag = this.props.tag || 'dd';

        return (
            <Tag className={classes} onClick={this._navigate} style={this.props.style}>
                {this.props.capsule ? (
                    <React.Fragment>
                        <span>{this.props.label}</span>
                        <i className={this.props.capsuleClass}>{this.props.capsule}</i>
                    </React.Fragment>
                ) : (
                    this.props.label
                )}
            </Tag>
        );
    }
}
