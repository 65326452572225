import React from 'react';
import { inject } from 'mobx-react';

@inject('store')
export class UnlessUserTag extends React.Component {
    render() {
        const tags = this.props.tag.split(',').map((tag) => tag.trim());
        if (this.props.store.session.info.user.hasAnyOfTag(tags)) {
            return null;
        }

        return this.props.children;
    }
}
