import React from 'react';

export function SVGImage(props) {
    const { viewBox, href, className, width, height, ...svgProps } = props;
    return (
        <svg className={className} viewBox={viewBox} {...svgProps}>
            <image xlinkHref={href} width={width} height={height} clipPath="url(#clipping)" />
        </svg>
    );
}
