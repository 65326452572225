import React from 'react';

export class PasswordSection extends React.Component {
    render() {
        return (
            <div className="selected">
                <article />
            </div>
        );
    }
}
