import React from 'react';
import { observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { formatPhoneNumber } from '@SUPPORT/utils';

import { SVGImage } from '@COMPONENTS/common/SVGImage';

@inject('store')
@withTranslation(['user'])
@observer
export class InfoSection extends React.Component {
    @observable buyerOrgs = [];
    @observable orgAdmins = [];
    @observable ready = false;

    componentDidMount() {
        let initialPromise = Promise.resolve();
        if (this.props.store.session.info.hasPermission('VIEW_BUYERS_INFO')) {
            const { user } = this.props.store.session.info;
            initialPromise = this.props.store.listUserBuyerOrgs(user.id).then((orgs) => (this.buyerOrgs = orgs));
        }

        initialPromise
            .then(() => this.props.store.listUserOrgAdmins())
            .then((admins) => (this.orgAdmins = admins))
            .then(() => (this.ready = true));
    }

    render() {
        if (!this.ready) {
            return null;
        }

        const { user } = this.props.store.session.info;
        const { t } = this.props;

        return (
            <div className="selected">
                <div className="header">
                    <div className="profilePic">
                        <SVGImage href="/graphics/silhouette@2x.png" viewBox="0 0 80 100" width="80" height="100" />
                        <h3>{user.fullName()}</h3>
                    </div>
                </div>
                <div className="scroll">
                    <form>
                        <h4>{t('user:account.info.title')}</h4>
                        <div className="full">
                            <label>{t('user:account.info.email-label')} :</label>
                            <a className="likeReadOnly">
                                <span>{user.email}</span>
                            </a>
                        </div>
                        <div>
                            <label>{t('user:account.info.mobile1-label')} :</label>
                            <a className="likeReadOnly" href={formatPhoneNumber(user.mobileNum1, 'RFC3966')}>
                                <span>{formatPhoneNumber(user.mobileNum1)}</span>
                            </a>
                        </div>
                        <div>
                            <label>{t('user:account.info.mobile2-label')} :</label>
                            <a className="likeReadOnly" href={formatPhoneNumber(user.mobileNum2, 'RFC3966')}>
                                <span>{formatPhoneNumber(user.mobileNum2)}</span>
                            </a>
                        </div>
                        <div>
                            <label>{t('user:account.info.phone-label')} :</label>
                            <a className="likeReadOnly" href={formatPhoneNumber(user.phoneNum, 'RFC3966')}>
                                <span>{formatPhoneNumber(user.phoneNum)}</span>
                            </a>
                        </div>
                        <div>
                            <label>{t('common:address')} :</label>
                            <address>
                                {user.address1}
                                <br />
                                {user.address2}
                                {user.address2 && <br />}
                                {user.zipCode} {user.city}
                                {(user.zipCode || user.city) && <br />}
                            </address>
                        </div>
                        {this.buyerOrgs.length > 0 ? (
                            <React.Fragment>
                                <h4>{t('user:account.info.buyers-title')}</h4>
                                <div>
                                    {this.buyerOrgs.map((org) => (
                                        <div key={org.id}>
                                            <label>{org.name}</label>
                                            <p>
                                                {org.address1}
                                                <br />
                                                {org.address2}
                                                <br />
                                                {org.zipCode} {org.city}
                                                <br />
                                                {org.country}
                                                <br />
                                            </p>
                                            <p className="note">
                                                {org.species.map((sp) => `${sp.name} (${sp.code})`).join(', ')}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment>
                        ) : null}
                        {this.orgAdmins.map((admin) => (
                            <React.Fragment key={admin.id}>
                                <h4>
                                    {t('user:account.info.admin-title')}: {admin.orgName}
                                </h4>
                                <div className="profilePic full">
                                    <SVGImage
                                        href="/graphics/silhouette@2x.png"
                                        viewBox="0 0 80 100"
                                        width="80"
                                        height="100"
                                    />
                                    <h3>
                                        {admin.fullName(false)}
                                        <span>
                                            <a href={`mailto:${admin.email}`}>{admin.email}</a>
                                            {admin.mobileNum1 ? ' - ' : null}
                                            <a href={`tel:${admin.mobileNum1}`}>
                                                {formatPhoneNumber(admin.mobileNum1)}
                                            </a>
                                            {admin.phoneNum ? ' - ' : null}
                                            <a href={`tel:${admin.phoneNum}`}>{formatPhoneNumber(admin.phoneNum)}</a>
                                        </span>
                                    </h3>
                                </div>
                                {admin.address1 && (
                                    <div>
                                        <label>{t('common:address')} :</label>
                                        <address>
                                            {admin.address1}
                                            {admin.address1 ? <br /> : null}
                                            {admin.address2}
                                            {admin.address2 ? <br /> : null}
                                            {admin.zipCode} {admin.city}
                                        </address>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </form>
                </div>
            </div>
        );
    }
}
