import { types, flow, getRoot } from 'mobx-state-tree';
import {
    startOfWeek,
    endOfWeek,
    subWeeks,
    startOfMonth,
    endOfMonth,
    subMonths,
    startOfYear,
    endOfYear,
    subYears
} from 'date-fns';

import { api } from '@SUPPORT/api';

import { User, UserZone } from '@STORES/common/user';
import { Species } from '@STORES/common/species';
import { Gear } from '@STORES/common/gears';

const TimeSpan = types
    .model('TimeSpan', {
        code: types.enumeration('TimeSpan', [
            'this-week',
            'last-week',
            'this-month',
            'last-month',
            'this-year',
            'last-year',
            'custom',
            'season'
        ]),
        fullCode: types.string,
        seasonId: types.maybe(types.number)
    })
    .preProcessSnapshot((snapshot) => {
        if (typeof snapshot === 'string') {
            if (snapshot.startsWith('season:')) {
                const [code, seasonId] = snapshot.split(':');
                return { code, fullCode: snapshot, seasonId: parseInt(seasonId, 10) };
            } else {
                return { code: snapshot, fullCode: snapshot };
            }
        }

        return snapshot;
    });

const StatEntry = types
    .model('StatEntry', {
        date: types.number,
        value: types.number,
        personCount: types.number,
        declarationCount: types.number,
        cumulative: types.number
    })
    .preProcessSnapshot((snapshot) => ({
        ...snapshot,
        date: typeof snapshot.date === 'string' ? new Date(snapshot.date).valueOf() : snapshot.date
    }));

export const StatisticsStore = types
    .model('StatisticsStore', {
        timeSpanFilter: types.optional(TimeSpan, 'this-month'),
        fromDateFilter: types.optional(types.Date, new Date()),
        toDateFilter: types.optional(types.Date, new Date()),
        speciesFilter: types.optional(types.reference(Species), '***'),

        userFilter: types.optional(
            types.reference(User, {
                get: (identifier, self) => {
                    const store = getRoot(self);
                    if (identifier === '***') {
                        return null;
                    } else {
                        return store.users.userWithId(identifier);
                    }
                },
                set: (value) => value
            }),
            '***'
        ),

        gearFilter: types.optional(
            types.reference(Gear, {
                get: (identifier, self) => {
                    const store = getRoot(self);
                    if (identifier === '***') {
                        return null;
                    } else {
                        return store.gears.list.find((g) => g.id === identifier);
                    }
                },
                set: (value) => value
            }),
            '***'
        ),

        zoneFilter: types.optional(
            types.reference(UserZone, {
                get: (identifier, self) => {
                    const store = getRoot(self);
                    const user = store.session.info.user;
                    const isAdmin = user.type === 'admin';
                    if (identifier === '***') {
                        return null;
                    } else if (isAdmin) {
                        return store.zones.list.find((z) => z.id === identifier);
                    } else {
                        return user.allZones.find((z) => z.zoneId === identifier);
                    }
                },

                set: (value) => {
                    const store = getRoot(self);
                    const user = store.session.info.user;
                    const isAdmin = user.type === 'admin';
                    return isAdmin ? value.id : value.zoneId;
                }
            }),
            '***'
        ),

        data: types.array(StatEntry)
    })
    .views((self) => {
        return {
            get filters() {
                const store = getRoot(self);
                const user = store.session.info.user;
                const isAdmin = user.type === 'admin' || user.type === 'superadmin';
                const isReader = user.type === 'reader';

                const filters = {
                    timeSpan: self.timeSpanFilter.code,
                    from: self.fromDateFilter.toISOString(),
                    to: self.toDateFilter.toISOString()
                };

                if (isAdmin || isReader) {
                    if (self.userFilter) {
                        filters.user = self.userFilter.id;
                    } else {
                        filters.orgId = store.session.info.org.id;
                    }
                } else {
                    filters.user = user.id;
                }

                if (self.speciesFilter && self.speciesFilter.code !== '***') {
                    filters.species = self.speciesFilter.code;
                }

                if (self.zoneFilter) {
                    filters.zone = self.zoneFilter.zoneId || self.zoneFilter.id;
                }

                if (self.gearFilter) {
                    filters.gear = self.gearFilter.id;
                }

                return filters;
            }
        };
    })
    .actions((self) => {
        const { app } = getRoot(self);
        return {
            afterCreate: () => {
                self.resetFilters();
            },

            resetFilters: () => {
                self.setUserFilter('***');
                self.setSpeciesFilter('***');
                self.setZoneFilter('***');
                self.setGearFilter('***');
                self.setTimeSpanFilter('this-month');
            },

            setUserFilter: (value) => {
                self.userFilter = value;
            },

            setSpeciesFilter: (value) => {
                self.speciesFilter = value;
            },

            setZoneFilter: (value) => {
                self.zoneFilter = value;
            },

            setGearFilter: (value) => {
                self.gearFilter = value;
            },

            setTimeSpanFilter: (value) => {
                self.timeSpanFilter = value;

                const now = new Date();
                switch (self.timeSpanFilter.code) {
                    case 'this-week':
                        self.fromDateFilter = startOfWeek(now, { weekStartsOn: 1 });
                        self.toDateFilter = endOfWeek(now, { weekStartsOn: 1 });
                        break;
                    case 'last-week':
                        self.fromDateFilter = startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
                        self.toDateFilter = endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
                        break;
                    case 'this-month':
                        self.fromDateFilter = startOfMonth(now);
                        self.toDateFilter = endOfMonth(now);
                        break;
                    case 'last-month':
                        self.fromDateFilter = startOfMonth(subMonths(now, 1));
                        self.toDateFilter = endOfMonth(subMonths(now, 1));
                        break;
                    case 'this-year':
                        self.fromDateFilter = startOfYear(now);
                        self.toDateFilter = endOfYear(now);
                        break;
                    case 'last-year':
                        self.fromDateFilter = startOfYear(subYears(now, 1));
                        self.toDateFilter = endOfYear(subYears(now, 1));
                        break;
                    case 'custom':
                    case 'default':
                        break;
                }
            },

            setDateFilters: (fromDate, toDate) => {
                self.fromDateFilter = fromDate;
                self.toDateFilter = toDate;
            },

            updateData: flow(function* () {
                try {
                    app.setBusy();
                    const { data } = yield api.fetchDeclarationsStatistics(self.filters);
                    self.data = data || [];
                } finally {
                    app.setBusy(false);
                }
            }),

            downloadCSV: () => {
                api.downloadDeclarationsStatistics(self.filters);
            }
        };
    });
