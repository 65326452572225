import { startOfMonth, addMonths, addDays, isAfter } from 'date-fns';

export function canDeclare(user, date) {
    // DPMA observers cannot declare
    if (user.hasTag('dpma')) {
        return false;
    }

    // Admins can always create or modify declarations
    if (user.type === 'admin' || user.type === 'superadmin') {
        return true;
    }

    // Standard users have more restrictions
    // - cannot declare after the 5th of the following month
    const followingMonth = startOfMonth(addMonths(date, 1));
    const followingMonthPlusFiveDays = addDays(followingMonth, 5);
    return isAfter(followingMonthPlusFiveDays, new Date());
}
