import { types, flow } from 'mobx-state-tree';

import { api } from '@SUPPORT/api';

export const LandingPort = types.model('LandingPort', {
    id: types.identifierNumber,
    countryCode: types.maybeNull(types.string),
    group: types.maybeNull(types.string),
    name: types.string
});

export const LandingPortsStore = types
    .model('LandingPort', {
        list: types.array(LandingPort)
    })
    .views((self) => {
        return {
            get grouped() {
                return self.list.reduce((map, lp) => {
                    if (lp.group && !map.has(lp.group)) {
                        map.set(lp.group, []);
                    }

                    map.get(lp.group).push(lp);
                    return map;
                }, new Map());
            }
        };
    })
    .actions((self) => {
        return {
            loadInitialData: flow(function* () {
                yield self.listAll('fr');
            }),

            listAll: flow(function* (countryCode = 'fr') {
                const response = yield api.listLandingPorts(countryCode);
                self.list = response.data;
            })
        };
    });
