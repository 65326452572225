import React from 'react';
import { observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { OrgChangeObserver } from '@COMPONENTS/common/OrgChangeObserver';

export function setZonesPopupValue(user, species, zoneId, selectCallback) {
    const speciesZones = zonesForSpecies(user, species);
    const zone = speciesZones.find((z) => z.zoneId === zoneId);
    if (zone && selectCallback) {
        selectCallback(zone);
    }
}

export function zonesForSpecies(user, species) {
    if (!user || !species || !user.allZones) {
        return [];
    }

    const zones =
        species === '***'
            ? user.allZones.filter((zone) => zone.allowed)
            : user.allZones.filter((zone) => zone.species.code === species && zone.allowed);

    return zones.sort((z1, z2) => z1.zoneName.localeCompare(z2.zoneName));
}

export function zonesForSpeciesAndOrg(user, species, orgId) {
    if (!user || !species || !user.allZones) {
        return [];
    }

    return user.allZones
        .filter((zone) => zone.species.code === species && zone.orgId === orgId && zone.allowed)
        .sort((z1, z2) => z1.zoneName.localeCompare(z2.zoneName));
}

@inject('store')
@withTranslation(['common'])
@observer
export class ZonesPopupMenu extends React.Component {
    @observable loading = true;
    @observable last = null;

    componentDidMount() {
        this._update();
    }

    _update = () => {
        const orgIdHint = this.props.user.type === 'superadmin' ? this.props.store.session.info.org.id : null;
        this.loading = true;
        this.props.user
            .listLastDeclarationZones()
            .then((zones) => (this.last = zones))
            .then(() => this.props.user.listZones(orgIdHint))
            .then(() => {
                setZonesPopupValue(
                    this.props.user,
                    this.props.forSpecies,
                    this.props.declaration.zoneId,
                    this.props.selectCallback
                );
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    };

    _zoneOrgs(list) {
        const orgs = new Map();
        list.forEach((zone) => orgs.set(zone.orgId, zone.orgName));
        return Array.from(orgs.entries());
    }

    render() {
        const { t, allowCatchAllZone } = this.props;

        let options;
        if (this.loading) {
            options = [
                <option value="" key="loading">
                    {t('common:loading')}...
                </option>
            ];
        } else {
            if (allowCatchAllZone) {
                options = [
                    <option value="***" key="catchall">
                        {t('common:all-zones')}
                    </option>
                ];
            } else {
                options = [
                    <option value="" key="empty">
                        {t('common:select')}...
                    </option>
                ];
            }

            // let forSpecies = this.props.forSpecies;
            // if (!forSpecies) {
            //     const species = this.props.store.species.withAnyTag(this.props.user.tags);
            //     const firstSpecies = species[0];
            //     forSpecies = firstSpecies && firstSpecies.code;
            // }

            // const zones = zonesForSpecies(this.props.user, forSpecies);
            const { user } = this.props;
            let zones = zonesForSpecies(user, '***');
            if (user.tags && user.tags.length === 1 && user.hasTag('pap')) {
                zones = zones.filter((z) => z.zoneTags && z.zoneTags.includes('pap'));
            }

            const zoneOrgs = this._zoneOrgs(zones);

            let fullList;
            if (zoneOrgs.length === 1) {
                fullList = zones.map((z) => (
                    <option value={z.zoneId} key={`${z.zoneId}-all`}>
                        {z.zoneName} [{z.zoneCode}]
                    </option>
                ));
            } else {
                fullList = zoneOrgs.map((org) => (
                    <optgroup label={org[1]} key={org[0]}>
                        {zonesForSpeciesAndOrg(this.props.user, '***', org[0]).map((z) => (
                            <option value={z.zoneId} key={`${org[0]}-${z.zoneId}`}>
                                {z.zoneName} {z.zoneCode && `[${z.zoneCode}]`}
                            </option>
                        ))}
                    </optgroup>
                ));
            }

            if (zones.length > 0 && this.last && this.last.length > 0) {
                const lastList = this.last.map((id) => {
                    const zone = zones.find((z) => z.zoneId === id);
                    if (!zone) {
                        return null;
                    }

                    return (
                        <option value={zone.zoneId} key={`${zone.zoneId}-last`}>
                            {zone.zoneName} {zone.zoneCode && `[${zone.zoneCode}]`}
                        </option>
                    );
                });

                options.push(
                    <optgroup label={t('common:declaration-dialog.last-used-zones') + ' :'} key="last">
                        {lastList}
                    </optgroup>
                );
                if (zoneOrgs.length === 1) {
                    options.push(
                        <optgroup label={t('common:all-zones') + ' :'} key="all">
                            {fullList}
                        </optgroup>
                    );
                } else {
                    options.push(<optgroup label={t('common:all-zones') + ' :'} key="all" />);
                    options = options.concat(fullList);
                }
            } else {
                options = options.concat(fullList);
            }
        }

        return (
            <OrgChangeObserver onChange={this._update}>
                <select {...this.props.selectProps}>{options}</select>
            </OrgChangeObserver>
        );
    }
}
