import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import { eachDayOfInterval, endOfMonth, endOfToday, getISODay, isAfter, isSameDay, setHours } from 'date-fns';

import { canDeclare } from '@SUPPORT/rules';
import { formatLocaleDate, capitalize } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';

@inject('store')
@observer
class Day extends React.Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date),
        empty: PropTypes.bool
    };

    @computed
    get isDisabled() {
        return isAfter(this.props.date, endOfToday());
    }

    _startReporting = () => {
        if (!this.props.date || this.isDisabled) {
            return;
        }

        this.props.store.startReportingOnDate(this.props.date);
    };

    render() {
        const reported = this.props.store.hadDeclarationOnDate(this.props.date);
        const classes = classNames('day', {
            empty: this.props.empty,
            today: isSameDay(this.props.date, Date.now()),
            disabled: this.isDisabled,
            reported: reported
        });

        return (
            <div className={classes} onClick={this.isDisabled ? null : this._startReporting}>
                {this.props.empty ? null : this.props.date.getDate()}
                {reported ? <SVGObject objectId="reportedIco" /> : null}
            </div>
        );
    }
}

@inject('store')
@observer
class Month extends React.Component {
    static propTypes = {
        year: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired
    };

    render() {
        const startDate = new Date(this.props.year, this.props.month, 1);
        const endDate = endOfMonth(startDate);
        const fillers = getISODay(startDate) - 1;
        const declarationAllowed = canDeclare(this.props.store.session.info.user, startDate);
        const hasValidatedMonth = this.props.store.hasValidatedDeclarations(this.props.month + 1);

        // TODO: localize day initials
        return (
            <div className={classNames('month', { disabled: isAfter(startDate, endOfMonth(new Date())) })}>
                <div className={classNames('monthName', { done: !declarationAllowed })}>
                    {capitalize(formatLocaleDate(startDate, 'MMMM'))}
                    {hasValidatedMonth && <SVGObject objectId="checkIco" />}
                </div>
                <div className="dayName">L</div>
                <div className="dayName">M</div>
                <div className="dayName">M</div>
                <div className="dayName">J</div>
                <div className="dayName">V</div>
                <div className="dayName">S</div>
                <div className="dayName">D</div>

                {Array.from(Array(fillers)).map((_, i) => (
                    <Day date={null} empty={true} key={`empty-${i}`} />
                ))}

                {eachDayOfInterval({ start: startDate, end: endDate }).map((date) => (
                    <Day date={setHours(date, 12)} key={date.getDate()} />
                ))}
            </div>
        );
    }
}

@inject('store')
@observer
class Year extends React.Component {
    static propTypes = {
        year: PropTypes.number.isRequired,
        className: PropTypes.string
    };

    componentDidMount() {
        this._update();
    }

    componentDidUpdate(prevProps) {
        if (this.props.year !== prevProps.year) {
            this._update();
        }
    }

    _update() {
        this.props.store.getDeclarationCalendar(this.props.year);
    }

    render() {
        return (
            <div className={classNames('panel', 'scroll', this.props.className)}>
                <Month year={this.props.year} month={0} />
                <Month year={this.props.year} month={1} />
                <Month year={this.props.year} month={2} />
                <Month year={this.props.year} month={3} />
                <Month year={this.props.year} month={4} />
                <Month year={this.props.year} month={5} />
                <Month year={this.props.year} month={6} />
                <Month year={this.props.year} month={7} />
                <Month year={this.props.year} month={8} />
                <Month year={this.props.year} month={9} />
                <Month year={this.props.year} month={10} />
                <Month year={this.props.year} month={11} />
            </div>
        );
    }
}

@inject('store')
@observer
export class CalendarSection extends React.Component {
    render() {
        const currentYear = new Date().getFullYear();
        return (
            <div className="calendar selected">
                <div className="header row">
                    <div
                        className={classNames('arrow', 'toPrevious', {
                            disabled: this.props.store.calendarYear - 1 < 2014
                        })}
                        onClick={this.props.store.jumpToPreviousYear}
                    >
                        {this.props.store.calendarYear - 1}
                    </div>
                    <h3>{this.props.store.calendarYear}</h3>
                    <div
                        className={classNames('arrow', 'toNext', {
                            disabled: this.props.store.calendarYear + 1 === currentYear + 1
                        })}
                        onClick={this.props.store.jumpToNextYear}
                    >
                        {this.props.store.calendarYear + 1}
                    </div>
                </div>
                <div className="panels">
                    <Year year={this.props.store.calendarYear} />
                </div>
            </div>
        );
    }
}
