import React from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import { canDeclare } from '@SUPPORT/rules';
import { formatDuration, formatLocaleDate, formatDeclarationValue, formatMoneyValue } from '@SUPPORT/utils';

import { SVGObject } from '@COMPONENTS/common/SVGObject';
import { WithUserTag } from '@COMPONENTS/common/WithUserTag';
import { UnlessUserTag } from '@COMPONENTS/common/UnlessUserTag';

@inject('store')
@withRouter
@withTranslation(['admin', 'common'])
class DeclarationRow extends React.Component {
    _jumpToBuyer = (evt) => {
        evt.preventDefault();
        this.props.history.push(`/people/buyer/${this.props.declaration.buyerOrgId}/info`);
    };

    _editDeclaration = () => {
        const store = this.props.user.hasTag('pfp')
            ? this.props.store.declarationWizard
            : this.props.store.declarationDialog;
        store.showForDeclaration(this.props.user, this.props.declaration, this.props.afterSave);
    };

    render() {
        const { declaration } = this.props;
        let allowDeclaration = canDeclare(this.props.store.session.info.user, declaration.eventDate);
        if (this.props.store.session.info.user.type === 'user') {
            allowDeclaration =
                allowDeclaration && !this.props.store.hasValidatedDeclarations(declaration.eventDate.getMonth() + 1);
        }

        let CR = null;
        if (declaration.tags && declaration.tags.includes('consumption')) {
            CR = <i className="purple">C</i>;
        } else if (declaration.tags && declaration.tags.includes('restocking')) {
            CR = <i className="purple">R</i>;
        }

        const { t, user } = this.props;
        return (
            <tr>
                {allowDeclaration ? (
                    <td onClick={this._editDeclaration} title={t('admin:user-history.modify-declaration')}>
                        <SVGObject className="tool" objectId="editIcon" />
                    </td>
                ) : (
                    <td />
                )}
                <td>
                    <span>{t('common:declaration-date')} :</span>
                    {formatLocaleDate(declaration.eventDate, 'P p')}
                </td>
                <UnlessUserTag tag="pal">
                    <td>
                        <span>{t('common:type')} :</span>
                        {declaration.type.toUpperCase()}
                    </td>
                </UnlessUserTag>
                {this.props.store.session.info.org.hasTag('pal') ? (
                    <td>
                        <span>{t('common:technique')} :</span>
                        {declaration.technique ? (
                            <i className="blue" title={declaration.technique.name}>
                                {declaration.technique.code}
                            </i>
                        ) : (
                            '-'
                        )}
                    </td>
                ) : (
                    <td>
                        <span>{t('common:gear')} :</span>
                        {declaration.gear ? (
                            <i className="blue" title={declaration.gear.name}>
                                {declaration.gear.code}
                            </i>
                        ) : (
                            '-'
                        )}
                    </td>
                )}
                <td>
                    <span>{t('common:species')} :</span>
                    <i className="yellow" title={declaration.species.name}>
                        {declaration.species.code}
                    </i>
                </td>
                <td>
                    <span>{t('common:zone-full')} :</span>
                    {declaration.zoneCode || '-'}&nbsp;{declaration.zoneName && `(${declaration.zoneName})`}
                </td>
                {this.props.store.session.info.org.hasFeature('landing_ports') && (
                    <td>
                        <span>Lieu de Débarquement</span>
                        {declaration.landingPort ? declaration.landingPort.name : '-'}
                    </td>
                )}
                <WithUserTag tag="pal">
                    <td>
                        <span>{t('common:size')} :</span>
                        {`${declaration.size} cm` || '-'}
                    </td>
                </WithUserTag>
                <td className="right">
                    <span>{t('common:weight')} :</span>
                    {formatDeclarationValue(declaration.value)}
                </td>
                <WithUserTag tag="pal">
                    <td>
                        <span>{t('common:knk')} :</span>
                        {declaration.hasTag('released') ? t('common:nokill') : t('common:kill')}
                    </td>
                </WithUserTag>
                <UnlessUserTag tag="pal">
                    <td>{CR}</td>
                </UnlessUserTag>
                <td>
                    {user.hasAnyOfTag(['pap', 'pep', 'pfp', 'pal']) ? (
                        <React.Fragment>
                            <span>{t('common:duration')}</span>
                            {this.props.declaration.duration ? formatDuration(this.props.declaration.duration) : '-'}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <span>{t('common:time')}</span>
                            {formatLocaleDate(declaration.eventDate, 'p')}
                        </React.Fragment>
                    )}
                </td>
                <WithUserTag tag="pap" unless="dpma">
                    <td className="right">
                        <span>{t('common:price')} :</span>
                        {formatMoneyValue(declaration.price / 100, true)}
                    </td>
                </WithUserTag>
                <UnlessUserTag tag="pal">
                    <td className="right">
                        <span>{t('common:cumulative')} :</span>
                        {formatDeclarationValue(declaration.cumulative)}
                    </td>
                </UnlessUserTag>
                {this.props.user && this.props.user.hasTag('civ') && (
                    <td>
                        <span>{t('common:buyer')} :</span>
                        {declaration.buyerOrgName || '-'}
                    </td>
                )}
            </tr>
        );
    }
}

@inject('store')
@withTranslation(['common'])
@observer
export class DeclarationsList extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <table>
                <thead>
                    <tr>
                        <UnlessUserTag tag="dpma">
                            <th />
                        </UnlessUserTag>
                        <th>{t('common:declaration-date')}</th>
                        <UnlessUserTag tag="pal">
                            <th>{t('common:type')}</th>
                        </UnlessUserTag>
                        {this.props.store.session.info.org.hasTag('pal') ? (
                            <th>{t('common:technique')}</th>
                        ) : (
                            <th>{t('common:gear')}</th>
                        )}
                        <th>{t('common:species')}</th>
                        <th>{t('common:zone-full')}</th>
                        {this.props.store.session.info.org.hasFeature('landing_ports') && <th>Lieu de Débarquement</th>}
                        <WithUserTag tag="pal">
                            <th>{t('common:size')}</th>
                        </WithUserTag>
                        <th className="right">{t('common:weight')}</th>
                        <WithUserTag tag="pal">
                            <th>{t('common:knk')}</th>
                        </WithUserTag>
                        <UnlessUserTag tag="pal">
                            <th />
                        </UnlessUserTag>
                        <th>
                            {this.props.user && this.props.user.hasAnyOfTag(['pap', 'pep', 'pfp', 'pal'])
                                ? t('common:duration')
                                : t('common:time')}
                        </th>
                        <WithUserTag tag="pap">
                            <th className="right">{t('common:price')}</th>
                        </WithUserTag>
                        <UnlessUserTag tag="pal">
                            <th className="right">{t('common:cumulative')}</th>
                        </UnlessUserTag>
                        {this.props.user && this.props.user.hasTag('civ') && <th>{t('common:buyer')}</th>}
                    </tr>
                </thead>
                <tbody>
                    {this.props.declarations.map((declaration) => (
                        <DeclarationRow
                            user={this.props.user}
                            declaration={declaration}
                            afterSave={this.props.update}
                            key={declaration.id}
                        />
                    ))}
                </tbody>
            </table>
        );
    }
}
