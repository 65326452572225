import React, { Fragment } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { Spinner } from '@COMPONENTS/common/Spinner';
import { SVGObject } from '@COMPONENTS/common/SVGObject';

import LogoSVG from '@GRAPHICS/licensee/logo.svg';
const PescaliceLogo = styled(LogoSVG)`
    --logo-width: 16rem;
    width: var(--logo-width);
    height: calc(var(--logo-width) * 0.18);
    color: var(--dark-blue);
`;

@inject('store')
@withTranslation(['common'])
@observer
export class Header extends React.Component {
    _logout = (evt) => {
        evt.preventDefault();
        this.props.store.session.destroy();
    };

    render() {
        const { t } = this.props;
        const { info } = this.props.store.session;
        const isPescaliceUser = info && info.user && info.user.type === 'manager' && info.user.hasTag('psc');
        const userInfo = info ? info.user.fullName(false) : '';
        const orgInfo = info && info.user.type === 'admin' && info.user.isNotRestricted ? ` - ${info.org.name}` : '';

        return (
            <header>
                <div>
                    <div>
                        {isPescaliceUser ? (
                            <PescaliceLogo />
                        ) : (
                            <Fragment>
                                <SVGObject className="long" objectId="logoTelecapeche" />
                                <SVGObject className="short" objectId="IconTelecapeche" />
                            </Fragment>
                        )}
                        <Spinner style={{ top: isPescaliceUser ? 7 : 12 }} />
                    </div>
                    <menu>
                        <ul>
                            <li>
                                {userInfo}
                                {orgInfo}
                            </li>
                            <li>
                                <button onClick={this._logout}>{t('common:header.logout-button')}</button>
                            </li>
                        </ul>
                    </menu>
                </div>
            </header>
        );
    }
}
